import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import convert from 'convert-units';
import axios from 'axios';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
import SaveDesignPopup from './components/toolbar/popup/savedesign';
import SubmitForQuotePopup from './components/toolbar/popup/submitforquote';
import DownloadSummaryPopup from './components/toolbar/popup/downloadsummary';
import DesignerAssistPopup from './components/toolbar/popup/designerassist';
import NewProjectPopup from './components/toolbar/popup/newproject';
import AutosavePromptPopup from './components/toolbar/popup/autosaveprompt';
import * as constants from './constants';
import { Plugins as PlannerPlugins } from 'KitchenConfigurator';
import { StyledAlert } from './components/toolbar/main/myComponents';
import { DEFAULT_FONT_FAMILY, TEXT_COLOR_NEUTRAL_1, SECONDARY_PURPLE_COLOR, BG_COLOR_HOVER, PRIMARY_GREEN_COLOR } from './constants';
import SnackBar from './components/atoms/Snackbar'
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';

const AWS = require('aws-sdk');

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm{
    border-radius: 10px;
  }
`;

export const StyledDialogContent = styled.span`
  font-family: ${DEFAULT_FONT_FAMILY};
  color: ${TEXT_COLOR_NEUTRAL_1};
  padding: 10px 30px;
  padding-top: 30px;
`

export const StyledDialogAction = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px 30px 25px;
`

export const StyledButton = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
  color: ${SECONDARY_PURPLE_COLOR};
  font-weight: bold;
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 16px;
  border-radius: 20px;
  :hover{
    background-color: ${BG_COLOR_HOVER};
  }
`

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET
});

import { Project } from './class/export';
import Translator from './translator/translator';
import actions from './actions/export';
import Catalog from './catalog/catalog';
import { objectsMap } from './utils/objects-utils';
import {
  ToolbarComponents,
  Content,
  SidebarComponents,
  FooterBarComponents,
  HeaderComponents,
  WizardStepComponents,
  MenubarComponents,
  SignComponents,
  MyProjectsComonents,
} from './components/export';
import { VERSION } from './version';
import './styles/export';
import { API_SERVER_URL, MODE } from './constants';
import jwtService from './components/login/jwtService';
import SubmitPromptPopup from './components/toolbar/popup/submitprompt/index';

const { Header } = HeaderComponents;
const { Toolbar } = ToolbarComponents;
const { Sidebar } = SidebarComponents;
const { FooterBar } = FooterBarComponents;
const { WizardStep } = WizardStepComponents;
const { Menubar } = MenubarComponents
const { Sign } = SignComponents;
const { MyProjects } = MyProjectsComonents;

const toolbarW = 0;
const sidebarW = 250;
const footerBarH = 20;

const wrapperStyle = {
  display: 'flex',
  flexFlow: 'row nowrap'
};

const uploadFiles = (data, filePath) => {
  var p1 = new Promise((resolve, reject) => {
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: filePath,
      Body: data,
      ContentType: 'application/json',
      ACL: 'public-read'
    };
    s3.putObject(params, function(err, data) {
      if (err) {
        reject(err);
        return;
      }
      console.log('success');
      resolve([data.Location]);
    });
  });
  return p1;
};

let saveAlert = '';
let saveInterval = '';

class KitchenConfigurator extends Component {
  constructor(props) {
    super(props);

    // utm tracking
    let utmParams = new URLSearchParams(
      this.props.location && this.props.location.search
    );

    this.state = {
      savePopupVisible: false,
      quotePopupVisible: false,
      assistPopupVisible: false,
      newProjectPopupVisible: false,
      isModalVisible: false,
      submitPromptVisible: false,
      savePromptVisible: false,
      showAgainVisible: true,
      isShowProperty: true,
      submitTosave: false,
      visible: false,
      signOpen: false,
      myProjectsOpen: false,
      myProjectsToLogin: false,
      downloadPopupVisible: false,
      elevationZoom: 1,
      elevationMoveX: 0,
      elevationMoveY: 0,

      // For Toolbar Item
      toolbar: '',
      reviewQuotePopupOpened: false,
      products: [],
      floorOpened: false,
      cabinetOpened: false,
      applianceOpened: false,
      finishingOpened: false,
      doorStyleOpen: false,
      wizardStepOpend: (this.props.match.params.pid === undefined || this.props.match.params.pid === "null") ? true : false,
      tutorialMouseEventsAdd: false,
      replaceCabinetFlag: false,
      isSnackBarOpen:false,
      isSnackBar1Open:false,
      snackBarMessage:"",
      isMenubarVisible: false
    };

    // For UTM tracking
    this.utm = {
      utm_source: utmParams.get('utm_source') || 'source',
      utm_medium: utmParams.get('utm_medium') || 'medium',
      utm_campaign: utmParams.get('utm_campaign') || 'campaign',
      utm_term: utmParams.get('utm_term') || 'term',
      utm_content: utmParams.get('utm_content') || 'content',
      gclid: utmParams.get('gclid') || 'gclid',
      msclkid: utmParams.get('msclkid') || 'msclkid',
      fbclid: utmParams.get('fbclid') || 'fbclid',
      ttclid: utmParams.get('ttclid') || 'ttclid'
    };

    this.jwtCheck();

    this.openFloor = this.openFloor.bind(this);
    this.openCabinet = this.openCabinet.bind(this);
    this.openFinishing = this.openFinishing.bind(this);
    this.openAppliance = this.openAppliance.bind(this);
    this.toggleDoorStyle = this.toggleDoorStyle.bind(this);
    this.onReviewQuoteClicked = this.onReviewQuoteClicked.bind(this);
    this.closeFloorTB = this.closeFloorTB.bind(this);
    this.closeCabinetTB = this.closeCabinetTB.bind(this);
    this.closeFinishingTB = this.closeFinishingTB.bind(this);
    this.closeApplianceTB = this.closeApplianceTB.bind(this);
    this.setToolbar = this.setToolbar.bind(this);
    this.replaceCabinet = this.replaceCabinet.bind(this);
    this.setSubmitToSave = this.setSubmitToSave.bind(this);
    this.setVisible = this.setVisible.bind(this);
    this.setMenubarVisible = this.setMenubarVisible.bind(this);
    this.setSignOpen = this.setSignOpen.bind(this);
    this.setMyProjectsOpen = this.setMyProjectsOpen.bind(this);
    this.setMyProjectsToLogin = this.setMyProjectsToLogin.bind(this);
    this.setDownloadPopupVisible = this.setDownloadPopupVisible.bind(this);
    this.neverShowInput = React.createRef(null);
    this.setShowProperty = this.setShowProperty.bind(this);
    this.setElevationZoom = this.setElevationZoom.bind(this);
    this.setElevationMoveX = this.setElevationMoveX.bind(this);
    this.setElevationMoveY = this.setElevationMoveY.bind(this);
  }

  // Toolbar control functions

  setToolbar(toolBarKey) {
    this.setState({ toolbar: toolBarKey });
  }

  setElevationZoom(value) {
    this.setState({elevationZoom: this.state.elevationZoom + value});
  }

  setElevationMoveX(value) {
    this.setState({elevationMoveX: this.state.elevationMoveX + value});
  }

  setElevationMoveY(value) {
    this.setState({elevationMoveY: this.state.elevationMoveY + value});
  }

  setShowProperty(value) {
    this.setState({isShowProperty: value});
  }
  
  setMyProjectsOpen(value) {
    this.setState({ myProjectsOpen: value });
    this.setState({ signOpen: false });
    this.setState({ wizardStepOpend: false });
    this.setState({ isMenubarVisible: false });
  }

  setMyProjectsToLogin(value) {
    this.setState({ myProjectsToLogin: value });
  }

  setDownloadPopupVisible(value) {
    this.setState({ reviewQuotePopupOpened: false });
    this.setState({ savePopupVisible: false });
    this.setState({ quotePopupVisible: false });
    this.setState({ submitTosave: false });
    this.setState({ assistPopupVisible: false });
    this.setState({ newProjectPopupVisible: false });
    this.setState({ downloadPopupVisible: value });
  }

  setSubmitToSave(value) {
    this.setState({ submitTosave: value })
  }

  setMenubarVisible(value) {
    this.setState({ isMenubarVisible: value });
  }

  setVisible(value) {
    this.setState({ visible: value });
  }

  setSignOpen(value) {
    this.setState({ wizardStepOpend: false });
    this.setState({ myProjectsOpen: false });
    this.setState({ isMenubarVisible: false });
    this.setState({ signOpen: value });
  }

  openFloor() {
    this.setState({ floorOpened: true });
    document.getElementById(
      'make_floorplan_inactive'
    ).parentElement.parentElement.style.zIndex = 999;
  }

  openCabinet() {
    this.setState({ cabinetOpened: true });
    document.getElementById(
      'add_cabinets_inactive'
    ).parentElement.parentElement.style.zIndex = 999;
  }

  toggleDoorStyle(visible) {
    this.setState({ doorStyleOpen: visible });
    document.getElementById(
      'select_doorstyle_inactive'
    ).parentElement.parentElement.style.zIndex = visible ? 999 : 6;
  }

  replaceCabinet(value) {
    this.setState({replaceCabinetFlag: value});
  }

  openAppliance() {
    this.setState({ applianceOpened: true });
    document.getElementById(
      'add_appliances_inactive'
    ).parentElement.parentElement.style.zIndex = 999;
  }

  openFinishing() {
    this.setState({ finishingOpened: true });
    document.getElementById(
      'finishing_touches_inactive'
    ).parentElement.parentElement.style.zIndex = 999;
  }

  onReviewQuoteClicked(visible) {
    this.setState({ reviewQuotePopupOpened: visible });
    document.getElementById(
      'review_quote_inactive'
    ).parentElement.parentElement.style.zIndex = visible ? 999 : 6;
  }

  closeFloorTB() {
    this.setState({ floorOpened: false });
    document.getElementById('make_floorplan_inactive') &&
      (document.getElementById(
        'make_floorplan_inactive'
      ).parentElement.parentElement.style.zIndex = 6);
  }

  closeCabinetTB() {
    this.setState({ cabinetOpened: false });
    document.getElementById(
      'add_cabinets_inactive'
    ).parentElement.parentElement.style.zIndex = 6;
  }

  closeFinishingTB() {
    this.setState({ finishingOpened: false });
    document.getElementById(
      'finishing_touches_inactive'
    ).parentElement.parentElement.style.zIndex = 6;
  }

  closeApplianceTB() {
    this.setState({ applianceOpened: false });
    document.getElementById(
      'add_appliances_inactive'
    ).parentElement.parentElement.style.zIndex = 6;
  }

  closeDoorstyle() {
    this.setState({});
    document.getElementById(
      'select_doorstyle_inactive'
    ).parentElement.parentElement.style.zIndex = 6;
  }
  closeReviewQuote() {
    this.setState({});
    document.getElementById(
      'review_quote_inactive'
    ).parentElement.parentElement.style.zIndex = 6;
  }

  jwtCheck() {
    jwtService.on('onAutoLogin', () => {
      // this.props.showMessage({message: 'Logging in with JWT'});

      /**
       * Sign in and retrieve user data from Api
       */
      jwtService
        .signInWithToken()
        .then(user => {
          //this.props.userActions.setUserData(user);
          this.props.userActions.login(
            user.id,
            user.role,
            jwtService.getAccessToken(),
            this.utm
          );
          //this.props.showMessage(option)
          //this.props.showMessage({message: 'Logged in with token data',  variant: 'success'});
          //alert('Logged in with token data');
        })
        .catch(error => {
          //this.props.showMessage({message: error});
          this.setState({
            isSnackBarOpen: true,
            snackBarMessage: error
          })
        });
    });

    jwtService.on('onAutoLogout', message => {
      if (message) {
        //this.props.showMessage({message, variant: 'error'});
      }
      this.props.userActions.logout();
    });

    jwtService.init();
  }

  getChildContext() {
    return {
      ...objectsMap(actions, actionNamespace => this.props[actionNamespace]),
      translator: this.props.translator,
      catalog: this.props.catalog
    };
  }

  saveProjectToStorage() {
    let { state, stateExtractor } = this.props;
    const extractedState = stateExtractor(state);
    const projectData = JSON.stringify(extractedState.get('scene').toJS());
    sessionStorage.setItem(window.location.href, projectData);
  }

  autoSaveProject(param) {
    // Get storage info
    let title = sessionStorage.getItem('projectTitle');
    let projectId = sessionStorage.getItem('projectId');
    let extractedState = this.props.stateExtractor(this.props.state);

    let projectTitle =
      (param && param.projectName) ||
      title ||
      `Project-${new Date().toLocaleString()}`;
    let utm = this.utm;

    // State
    let state = Project.unselectAll(extractedState).updatedState;
    let { scene } = state;
    state = state.merge({ scene: scene.merge({ title: projectTitle }) });

    // Set storage info
    sessionStorage.setItem('projectTitle', projectTitle);
    this.props.projectActions.rename(projectTitle);

    // Data to save
    const projectData = JSON.stringify(state.get('scene').toJS());

    axios
      .post('/api/project/saveProject', {
        pid: projectId,
        title: projectTitle,
        mode: MODE,
        access_token: this.props.match.params.token ? this.props.match.params.token : null,
        userrole: this.props.match.params.role ? this.props.match.params.role : null,
        saveData: { ...param, ...utm, projectData }
      })
      .then(response => {
        const { success, userData, access_token } = response.data;
        if (success === true) {
          const { insertId } = response.data.execResult;
          const currentProjectID =
            insertId !== 0
              ? insertId
              : state.getIn(['scene', 'currentProjectID']);

          // Set storage data
          sessionStorage.setItem('projectId', currentProjectID);
          sessionStorage.setItem('email', param.email);
          sessionStorage.setItem('phone', param.phone);
          sessionStorage.setItem('firstName', param.firstName);
          sessionStorage.setItem('lastName', param.lastName);
          this.props.projectActions.setProjectID({ currentProjectID });
          actions.userActions.login(
            userData[0].id,
            userData[0].role,
            access_token,
            utm
          );
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  componentDidMount() {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    hotjar.event && hotjar.event(`pageview:${window.location.pathname}`);

      /*
      When the page refreshed the project data do not lost.
    */
      window.onbeforeunload = () => {
        this.saveProjectToStorage();
        return null;
      };

    window.forRedo = [];
    let { store } = this.context;
    let { projectActions, catalog, stateExtractor, state, plugins } = this.props;
    let extractedState = stateExtractor(state);

    saveInterval = setInterval(() => {
      return;//if(window.location.pathname !== '/' && window.location.pathname.split('/')[2] !== 'customer') return;
      let extractState = stateExtractor(this.props.state);
      const areas = extractState.getIn(['scene', 'layers', extractState.scene.selectedLayer, 'areas']);
      const items = extractState.getIn(['scene', 'layers', extractState.scene.selectedLayer, 'items']);
      let showAgain = sessionStorage.getItem('prompt_show_again') ? sessionStorage.getItem('prompt_show_again') : "true";
      if(areas.size >= 1 && items.size >= 3 && !this.state.savePopupVisible){
        if(sessionStorage.email === null || sessionStorage.email === undefined || sessionStorage.email === 'temp_xxx@yyy.com') {
          if(items.size >= 8 && showAgain === "true") {
            this.setState({savePromptVisible: true, showAgainVisible: true});
          } else {
            this.autoSaveProject({email: 'temp_xxx@yyy.com', phone: '', notes: '', firstName: 'temp_xxx', lastName: 'temp_yyy', projectName: 'Untitled', snackBarMessage: ""})
          }
        } else if(sessionStorage.email && sessionStorage.email !== 'temp_xxx@yyy.com') {
          this.autoSaveProject({ projectName: extractState.scene.title, projectId: sessionStorage.getItem('projectId'), email: sessionStorage.getItem('email'), firstName : sessionStorage.getItem('firstName'),lastName : sessionStorage.getItem('lastName'), phone : sessionStorage.getItem('phone') });
        }
      }
    }, 300000);

    projectActions.initCatalog(catalog);

    let { match } = this.props;

    if (sessionStorage.getItem(window.location.href)) {
      const jsonData = sessionStorage.getItem(window.location.href);
      projectActions.loadProject(JSON.parse(jsonData));
      sessionStorage.removeItem(window.location.href);
      return;
    }

    if (match && match.params.pid === undefined) {
      let newplugins = [
        ...plugins,
        PlannerPlugins.Autosave('KitchenConfigurator_v0', false)
      ];
      newplugins.forEach(newplugin => newplugin(store, stateExtractor));
      projectActions.newProject();
      sessionStorage.setItem('projectTitle', 'Untitled');
      projectActions.rename('Untitled');
      sessionStorage.removeItem('projectId');
      sessionStorage.removeItem('email');
      sessionStorage.removeItem('firstName');
      sessionStorage.removeItem('prompt_show_again');
      sessionStorage.removeItem('lastName');
      sessionStorage.removeItem('usedObjects');
      return;
    }

    if (match && match.params.pid !== undefined) {
      if (match.params.pid === 'new') {
        projectActions.newProject();
        sessionStorage.setItem('projectTitle', 'Untitled');
        projectActions.rename('Untitled');
        sessionStorage.removeItem('projectId');
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('firstName');
        sessionStorage.removeItem('prompt_show_again');
        sessionStorage.removeItem('lastName');
        sessionStorage.removeItem('usedObjects');
      } else {
        axios
          .post(`${constants.API_SERVER_URL}/api/project/loadPidProject`, {
            pid: match.params.pid
          })
          .then(async response => {
            const { projectElement } = response.data;
            console.log(projectElement);
            if (projectElement.length === 0) return;
            sessionStorage.setItem('projectTitle', projectElement[0].title);
            projectActions.rename(projectElement[0].title);
            sessionStorage.setItem('projectId', projectElement[0].id);
            sessionStorage.setItem('email', projectElement[0].email);
            sessionStorage.setItem('firstName', projectElement[0].firstName);
            sessionStorage.setItem('lastName', projectElement[0].lastName);
            sessionStorage.setItem('phone', projectElement[0].phone);
            let jsonData;
            if (projectElement[0].project_data) {
              jsonData = JSON.parse(projectElement[0].project_data);
            } else {
              try {
                jsonData = await axios.post(
                  `${constants.API_SERVER_URL}/api/project/loadPidData`,
                  { pid: match.params.pid }
                ).data.data;
              } catch (err) {
                this.setState({
                  isSnackBarOpen: true,
                  snackBarMessage: err
                })
              }
            }
            jsonData.isLoadingCabinet = false;
            projectActions.loadProject(jsonData);
          });
      }
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  componentWillMount() {}

  componentWillReceiveProps(nextProps) {
    let { stateExtractor, state, projectActions, catalog } = nextProps;
    let plannerState = stateExtractor(state);
    let catalogReady = plannerState.getIn(['catalog', 'ready']);
    if (!catalogReady) {
      projectActions.initCatalog(catalog);
    }
  }

  isProjectEmpty(scene) {
    let { layers, selectedLayer } = scene;
    let layer = layers.get(selectedLayer);
    return (
      layer.areas.size +
        layer.lines.size +
        layer.holes.size +
        layer.items.size ===
      0
    );
  }

  handleSaveDesign(value) {
    let { stateExtractor, state } = this.props;
    let plannerState = stateExtractor(state);
    let items = plannerState.getIn(['scene', 'layers', 'layer-1', 'items']);
    let areas = plannerState.getIn(['scene', 'layers', 'layer-1', 'areas']);
    if (areas.size === 0) {
      this.setState({
        isSnackBarOpen: true,
        snackBarMessage: "Your project is empty."
      })
      return;
    }
    // if (items.size === 0) {
    //   saveAlert = <StyledAlert text={<span style={{fontSize: 16, fontFamily: DEFAULT_FONT_FAMILY}}>Add at least one item</span>} onClose={() => saveAlert = ""} />
    //   return;
    // } else saveAlert = '';
    this.setState({ reviewQuotePopupOpened: false });
    this.setState({ savePopupVisible: value });
    this.setState({ quotePopupVisible: false });
    this.setState({ assistPopupVisible: false });
    this.setState({ newProjectPopupVisible: false });
    this.setState({ savePromptVisible: false });
    this.setState({ submitPromptVisible: false })
  }

  handleNewProject(value) {
    this.setState({ reviewQuotePopupOpened: false });
    this.setState({ savePopupVisible: false });
    this.setState({ quotePopupVisible: false });
    this.setState({ assistPopupVisible: false });
    this.setState({ newProjectPopupVisible: value });
  }

  handleSubmitForQuote(value) {
    this.setState({ reviewQuotePopupOpened: false });
    this.setState({ savePopupVisible: false });
    this.setState({ quotePopupVisible: value });
    this.setState({ submitTosave: false });
    this.setState({ assistPopupVisible: false });
    this.setState({ newProjectPopupVisible: false });
  }

  handleDesignerAssist(value) {
    this.setState({ reviewQuotePopupOpened: false });
    this.setState({ savePopupVisible: false });
    this.setState({ quotePopupVisible: false });
    this.setState({ assistPopupVisible: value });
    this.setState({ newProjectPopupVisible: false });
  }

  saveScreenshotToFile = event => {
    event.preventDefault();
    let canvas = document.getElementsByID('viewer3D')[1];
    let canvas_crop_image = document.getElementById ("canvas_crop_image");
    let ctx = canvas_crop_image.getContext('2d');

    // Set width and height for the new canvas
    let heightAtt = document.createAttribute('height');
    heightAtt.value = canvas.height;
    canvas_crop_image.setAttributeNode(heightAtt);

    let widthAtt = document.createAttribute('width');
    widthAtt.value = canvas.width;
    canvas_crop_image.setAttributeNode(widthAtt);

    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas_crop_image.width, canvas_crop_image.height);

    let img = new Image ();
    img.crossOrigin = 'anonymous';
    img.src = canvas.toDataURL();

    img.onload = () => {
      ctx.globalAlpha = 0.3;
      ctx.drawImage(img, 0, 0, canvas_crop_image.width, canvas_crop_image.height);
      this.setState({
        isModalVisible: true,
        imageUri: canvas.toDataURL(),
        image: img
      });
      canvas_crop_image.style.zIndex = 11;
      document.addEventListener ("keydown", this.onKeyDown, false);
      document.addEventListener ("mouseup", this.onMouseUp_On_New_Save_SQ_DA, false);
      canvas_crop_image.style.display = "block";
    };
  };

  render() {
    let { width, height, state, stateExtractor, ...props } = this.props;
    let {
      savePopupVisible,
      quotePopupVisible,
      assistPopupVisible,
      newProjectPopupVisible,
      savePromptVisible,
      showAgainVisible,
      submitPromptVisible,
      submitTosave,
      visible,
      isMenubarVisible,
      signOpen,
      myProjectsOpen,
      myProjectsToLogin,
      downloadPopupVisible,
    } = this.state;
    let contentW = width - toolbarW;
    let categoryData = this.props.categoryData;
    // console.log(categoryData);
    // let contentW = width - toolbarW - sidebarW;
    // let toolbarH = height - footerBarH;
    // let contentH = height - footerBarH;
    // let sidebarH = height - footerBarH;
    let toolbarH = height;
    let contentH = height;
    let sidebarH = height;
    let headerW = width;
    let headerH = 60;

    let extractedState = stateExtractor(state);
    //console.log('KC state - mode: ', extractedState.mode);
    let doorStyle = extractedState.get('doorStyle');
    if (
      extractedState.getIn([
        'scene',
        'layers',
        extractedState.scene.selectedLayer,
        'doorStyle'
      ]) !== undefined &&
      extractedState.getIn([
        'scene',
        'layers',
        extractedState.scene.selectedLayer,
        'doorStyle'
      ]) !== null
    ) {
      const extractedStateData = extractedState.getIn([
        'scene',
        'layers',
        extractedState.scene.selectedLayer,
        'doorStyle'
      ]);
      let doorIdAvailable = false
      if(this.props.categoryData.doorStyles && this.props.categoryData.doorStyles.items){
        this.props.categoryData.doorStyles.items.find(category => {
          doorIdAvailable = category.items.find(style => {
            let item = style.items.find(it => it.id === extractedStateData.id);
            if (item) {
              colorItem = item;
              return true;
            } else {
              return false;
            }
          })
        })
      }

      if(doorIdAvailable) doorStyle = extractedStateData
    }

    let firstVisit = this.state.wizardStepOpend && this.isProjectEmpty(extractedState.scene);
    let allVisible = firstVisit || signOpen || myProjectsOpen;

    let _scene = extractedState.getIn(['scene']);
    let len = convert(_scene.width)
      .from(_scene.unit)
      .to('cm');
    let _viewer2D = extractedState.getIn(['viewer2D']);
    if (_viewer2D.size > 0) {
      let scaleX = (width / len) * 3;
      let scaleY = (height / len) * 3;
      let scale = scaleX > scaleY ? scaleY : scaleX;
      let _e = width - len * scale,
        _f = height - len * scale;
      let viewer = _viewer2D.toJS();
      if (viewer.e === 0 && viewer.f === 0) {
        _viewer2D = _viewer2D.merge({
          e: viewer.viewerWidth / 2 - viewer.SVGWidth / 2,
          f: viewer.viewerHeight / 2 - viewer.SVGHeight / 2,
          a: 0.99,
          d: 0.99
        });
      }
    }
    extractedState = extractedState.merge({
      viewer2D: _viewer2D
    });

    let userInfo = [];
    if (this.props.match) userInfo = this.props.match.params;
    var location = this.props.location;
    var history = this.props.history;

    let newProject = () => {
      this.props.projectActions.newProject();
      sessionStorage.setItem('projectTitle', 'Untitled');
      this.props.projectActions.rename('Untitled');
      sessionStorage.removeItem('projectId');
      sessionStorage.removeItem('prompt_show_again')
      sessionStorage.removeItem('usedObjects');
      history.push({pathname: `/project/${this.props.match.params.role}/${this.props.match.params.token}/null?null`});
      this.setState({wizardStepOpend: false});
    };

    /**
     * @description Save Projects
     * @param {{email,firstName,lastName,notes,projectName}} param Project information to save
     */
    let saveProject = param => {
      // Get storage info
      let title = sessionStorage.getItem('projectTitle');
      let projectId = sessionStorage.getItem('projectId');

      let projectTitle =
        (param && param.projectName) ||
        title ||
        `Project-${new Date().toLocaleString()}`;
      let utm = this.utm;

      // State
      let state = Project.unselectAll(extractedState).updatedState;
      let { scene } = state;
      state = state.merge({ scene: scene.merge({ title: projectTitle }) });

      // Set storage info
      sessionStorage.setItem('projectTitle', projectTitle);
      props.projectActions.rename(projectTitle);

      // Data to save
      const projectData = JSON.stringify(state.get('scene').toJS());

      axios
        .post('/api/project/saveProject', {
          pid: projectId,
          title: projectTitle,
          mode: MODE,
          access_token: userInfo.token ? userInfo.token : null,
          userrole: userInfo.role ? userInfo.role : null,
          saveData: { ...param, ...utm, projectData },
          url: window.location.hostname,
        })
        .then(response => {
          const { success, userData, access_token } = response.data;
          if (success === true) {
            const { insertId } = response.data.execResult;
            const currentProjectID =
              insertId !== 0
                ? insertId
                : state.getIn(['scene', 'currentProjectID']);

            // Set storage data
            console.log(param)
            sessionStorage.setItem('projectId', currentProjectID);
            sessionStorage.setItem('email', param.email);
            sessionStorage.setItem('phone', param.phone);
            sessionStorage.setItem('firstName', param.firstName);
            sessionStorage.setItem('lastName', param.lastName);
            props.projectActions.setProjectID({ currentProjectID });
            actions.userActions.login(
              userData[0].id,
              userData[0].role,
              access_token,
              utm
            );
            this.setState({
              isSnackBarOpen: true,
              snackBarMessage: "Successfully saved. <br /> Check your email for project details."
            });
            if(this.state.submitTosave) this.setState({visible: true});
          } else {
            this.setState({
              isSnackBarOpen: true,
              snackBarMessage: "Failed to add project data"
            })
          }
        })
        .catch(err => {
          console.error(err);
          this.setState({
            isSnackBarOpen: true,
            snackBarMessage: "Failed to add project data."
          })
        });
    };

    let pidSplit = userInfo.pid && userInfo.pid.indexOf('?');
    // If pidSplit is undefined pid is undefined
    let pid =
      pidSplit === undefined || pidSplit === -1
        ? userInfo.pid
        : userInfo.pid.slice(0, pidSplit);

    let submitForQuote = param => {
      if (!userInfo.token) {
        this.setState({
          isSnackBarOpen: true,
          snackBarMessage: "You should save the project first."
        })
        return;
      }
      axios
        .post(`${API_SERVER_URL}/api/project/submitProject`, {
          access_token: userInfo.token,
          data: param,
          num: true,
          projectid: pid,
          search: this.props.location.search,
          userrole: userInfo.role,
          url: window.location.hostname,
        })
        .then(response => {
          const { success } = response.data;
          if (success) {
            param.email && sessionStorage.setItem('email', param.email);
            param.phone && sessionStorage.setItem('phone', param.phone);
            this.setState({
              isSnackBarOpen: true,
              snackBarMessage: `Project submitted successfully.${(param.question1 || param.question2) ? ' <br /> A confirmation has been emailed to you and a designer will be in contact shortly' : ''}`,
              isSnackBar1Open: param.question3
            })
          } else this.setState({
            isSnackBarOpen: true,
            snackBarMessage: "Submitting project failed."
          })
        })
        .catch(err => {
          console.log('err', err);
        });
    };

    let designerAssist = ({ email, phone, issue_type, notes, question1, question2, question3, question4 }) => {
      console.log('dd', history);
      if (history.location.pathname != '/' || history.location.hash != '') {
        var path = history.location.pathname;
        var params = path.split('/');
        console.log(path, params);
        if (params.length > 3) {
          if (!params[params.length - 2]) {
            this.setState({
              isSnackBarOpen: true,
              snackBarMessage: "You should save the project first."
            })
            return;
          }
          axios
            .post(`${API_SERVER_URL}/api/project/designerAssist`, {
              email,
              phone,
              issue_type,
              notes,
              question1,
              question2,
              question3,
              question4,
              num: true,
              projectid: pid,
              url: window.location.hostname,
              access_token: params[params.length - 2],
              userrole: params[params.length - 3]
            })
            .then(response => {
              const { success } = response.data;
              if (success) {
                sessionStorage.setItem('phone', phone);
                this.setState({
                  isSnackBarOpen: true,
                  snackBarMessage: 'Assistance requested successfully. <br /> A designer will soon be in touch via your preferred communication method.'
                })
              } else this.setState({
                isSnackBarOpen: true,
                snackBarMessage: 'Assistance failed.'
              })
            })
            .catch(err => {
              console.log('err', err);
            });
        } else {
          this.setState({
            isSnackBarOpen: true,
            snackBarMessage: 'You should save the project first.'
          })
        }
      } else {
        this.setState({
          isSnackBarOpen: true,
          snackBarMessage: 'You should save the project first.'
        })
      }
    };

    return (
      <section>
        <SnackBar isOpen={this.state.isSnackBarOpen} message={this.state.snackBarMessage} handleClose={()=>this.setState({isSnackBarOpen:false, snackBarMessage: ""})} autoHideDuration={10000} />
        <SnackBar
          style={{marginTop: this.state.isSnackBarOpen ? 75 : 'unset'}}
          isOpen={this.state.isSnackBar1Open}
          message={"The items in your design have been added to your shopping cart at TheRTAStore.com. <br />Please log in with the same email address."}
          autoHideDuration={20000}
          action={<div>
            <a href="https://www.thertastore.com/checkout/cart" target="_blank" style={{fontFamily: DEFAULT_FONT_FAMILY, color: PRIMARY_GREEN_COLOR, fontWeight: 700, marginRight: 10}}>View Cart</a>
            <img
              onClick={()=>this.setState({isSnackBar1Open:false})}
              style={{ float: 'right', height: 24, cursor: 'pointer' }}
              src="/assets/img/svg/close.svg"
            />
          </div>}
        />
        <div style={{ ...wrapperStyle, height }}>
          <Header
            width={headerW}
            height={headerH}
            state={extractedState}
            reviewQuoteHandler={this.onReviewQuoteClicked.bind(this)}
            handleSaveDesign={this.handleSaveDesign.bind(this)}
            handleSubmitForQuote={this.handleSubmitForQuote.bind(this)}
            handleNewProject={this.handleNewProject.bind(this)}
            handleDesignerAssist={this.handleDesignerAssist.bind(this)}
            handleSubmitPrompt={() => this.setState({ submitPromptVisible: true })}
            saveProject={saveProject}
            newProject={newProject}
            isModalVisible={this.state.isModalVisible}
            allVisible={allVisible}
            wizardStepOpend={firstVisit}
            setMenubarVisible={this.setMenubarVisible}
            {...props}
          />
          <Menubar
            state={extractedState}
            visible={isMenubarVisible}
            setMenubarVisible={this.setMenubarVisible}
            setSignOpen={this.setSignOpen.bind(this)}
            newProject={newProject}
            setDownloadPopupVisible={this.setDownloadPopupVisible.bind(this)}
            setMyProjectsOpen={this.setMyProjectsOpen.bind(this)}
            handleNewProject={this.handleNewProject.bind(this)}
            handleDesignerAssist={this.handleDesignerAssist.bind(this)}
            handleSubmitForQuote={this.handleSubmitForQuote.bind(this)}
            handleSubmitPrompt={() => this.setState({ submitPromptVisible: true })}
            setMyProjectsToLogin={this.setMyProjectsToLogin.bind(this)}
            {...props}
          />
          {signOpen && (<Sign
            setSignOpen={this.setSignOpen.bind(this)}
            setMyProjectsOpen={this.setMyProjectsOpen.bind(this)}
            setMyProjectsToLogin={this.setMyProjectsToLogin.bind(this)}
            myProjectsToLogin={myProjectsToLogin}
          />)}
          {!allVisible && (
            <Toolbar
              width={toolbarW}
              content={contentW}
              height={toolbarH}
              headerH={headerH}
              state={extractedState}
              layers={extractedState.scene.layers}
              selectedLayer={extractedState.scene.selectedLayer}
              {...props}
              handleSaveDesign={this.handleSaveDesign.bind(this)}
              handleSubmitForQuote={this.handleSubmitForQuote.bind(this)}
              handleNewProject={this.handleNewProject.bind(this)}
              handleDesignerAssist={this.handleDesignerAssist.bind(this)}
              handleSubmitPrompt={() => this.setState({ submitPromptVisible: true })}
              toolBar = { this.state.toolbar }
              setToolbar = { this.setToolbar }
              setDownloadPopupVisible = {this.setDownloadPopupVisible.bind(this)}
              openFloor={this.openFloor}
              openCabinet={this.openCabinet}
              openAppliance={this.openAppliance}
              openFinishing={this.openFinishing}
              closeFloorTB={this.closeFloorTB}
              closeCabinetTB={this.closeCabinetTB}
              closeFinishingTB={this.closeFinishingTB}
              closeApplianceTB={this.closeApplianceTB}
              setShowProperty={this.setShowProperty}
              reviewQuotePopupOpened={this.state.reviewQuotePopupOpened}
              products={ this.props.products }
              isShowProperty={this.state.isShowProperty}
              categoryData={ this.props.categoryData }
              floorOpened={this.state.floorOpened}
              cabinetOpened={this.state.cabinetOpened}
              applianceOpened={this.state.applianceOpened}
              finishingOpened={this.state.finishingOpened}
              doorStyleOpen={this.state.doorStyleOpen}
              toggleDoorStyle={this.toggleDoorStyle}
              onReviewQuoteClicked={this.onReviewQuoteClicked}
              wizardStepOpend={firstVisit}
              replaceCabinetFlag={this.state.replaceCabinetFlag}
              replaceCabinet={this.replaceCabinet}
            />
          )}
          <Content
            width={contentW}
            height={contentH}
            catalog={this.props.catalog}
            state={extractedState}
            toolBar={this.state.toolbar}
            setToolbar={this.setToolbar}
            replaceCabinet={this.replaceCabinet}
            keyDownEnable={!savePopupVisible && !quotePopupVisible && !assistPopupVisible}
            elevationZoom={this.state.elevationZoom}
            elevationMoveX={this.state.elevationMoveX}
            elevationMoveY={this.state.elevationMoveY}
            {...props}
            onWheel={event => event.preventDefault()}
          />
          {!allVisible && (
            <FooterBar
              content={contentW}
              layers={extractedState.scene.layers}
              setShowProperty={this.setShowProperty}
              state={extractedState}
              selectedLayer={extractedState.scene.selectedLayer}
              elevationZoom={this.state.elevationZoom}
              setElevationZoom={this.setElevationZoom.bind(this)}
              setElevationMoveX={this.setElevationMoveX.bind(this)}
              setElevationMoveY={this.setElevationMoveY.bind(this)}
              {...props}
            />
          )}
          {firstVisit && (
            <WizardStep
              key="first-setting"
              content={contentW}
              layers={extractedState.scene.layers}
              close={() => this.setState({ wizardStepOpend: false })}
              state={extractedState}
              setMyProjectsToLogin={this.setMyProjectsToLogin.bind(this)}
              setSignOpen={this.setSignOpen.bind(this)}
              selectedLayer={extractedState.scene.selectedLayer}
              setMyProjectsOpen={this.setMyProjectsOpen.bind(this)}
              {...props}
            />
          )}
          {myProjectsOpen && (
            <MyProjects
              setMyProjectsOpen={this.setMyProjectsOpen.bind(this)}
              {...props}
            />
          )}
          <SaveDesignPopup
            visible={savePopupVisible}
            submitTosave={submitTosave}
            setVisible={this.setVisible.bind(this)}
            handleSaveDesign={this.handleSaveDesign.bind(this)}
            saveProject={saveProject}
            state={extractedState}
          />
          <SubmitForQuotePopup
            categoryData={categoryData}
            curDoorStyle={doorStyle}
            handleSubmitForQuote={this.handleSubmitForQuote.bind(this)}
            submitForQuote={submitForQuote}
            visible={quotePopupVisible}
            setDoorStyle={this.props.itemsActions.setDoorStyle}
          />
          <DesignerAssistPopup
            visible={assistPopupVisible}
            handleDesignerAssist={this.handleDesignerAssist.bind(this)}
            designerAssist={designerAssist}
          />
          <NewProjectPopup
            visible={newProjectPopupVisible}
            handleNewProject={this.handleNewProject.bind(this)}
            newProject={newProject}
          />
          <SubmitPromptPopup
            visible={submitPromptVisible}
            handleOk={(value) => this.handleSaveDesign(value) || this.setState({ submitTosave: true })}
            handleClose={() => this.setState({submitPromptVisible: false})}
          />
          <AutosavePromptPopup
            visible={savePromptVisible}
            showAgainVisible={showAgainVisible}
            handleOk={this.handleSaveDesign.bind(this)}
            handleClose={() => this.setState({savePromptVisible: false, submitTosave: false})}
          />
          <DownloadSummaryPopup
            visible={downloadPopupVisible}
            setDownloadPopupVisible={this.setDownloadPopupVisible.bind(this)}
          />
          <StyledDialog open={visible} onClose={() => this.setState({ visible: false })}>
            <StyledDialogContent>Do you want to submit the project now?</StyledDialogContent>
            <StyledDialogAction>
              <StyledButton onClick={() => this.setState({ quotePopupVisible: true, visible: false })}>Yes</StyledButton>
              <StyledButton onClick={() => this.setState({ visible: false, submitTosave: false })}>No</StyledButton>
            </StyledDialogAction>
          </StyledDialog>
          {saveAlert}
        </div>
      </section>
    );
  }
}

KitchenConfigurator.propTypes = {
  translator: PropTypes.instanceOf(Translator),

  catalog: PropTypes.instanceOf(Catalog),
  allowProjectFileSupport: PropTypes.bool,
  plugins: PropTypes.arrayOf(PropTypes.func),
  autosaveKey: PropTypes.string,
  autosaveDelay: PropTypes.number,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  stateExtractor: PropTypes.func.isRequired,
  toolbarButtons: PropTypes.array,
  sidebarComponents: PropTypes.array,
  footerbarComponents: PropTypes.array,
  customContents: PropTypes.object,
  softwareSignature: PropTypes.string
};

KitchenConfigurator.contextTypes = {
  store: PropTypes.object.isRequired
};

KitchenConfigurator.childContextTypes = {
  ...objectsMap(actions, () => PropTypes.object),
  translator: PropTypes.object,
  catalog: PropTypes.object
};

KitchenConfigurator.defaultProps = {
  translator: new Translator(),
  catalog: new Catalog(),
  plugins: [],
  allowProjectFileSupport: true,
  softwareSignature: `KitchenConfigurator ${VERSION}`,
  toolbarButtons: [],
  sidebarComponents: [],
  footerbarComponents: [],
  customContents: {}
};

//redux connect
function mapStateToProps(reduxState) {
  return {
    state: reduxState
  };
}

function mapDispatchToProps(dispatch) {
  return objectsMap(actions, actionNamespace =>
    bindActionCreators(actions[actionNamespace], dispatch)
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KitchenConfigurator);
