import React, { useRef, useEffect, useState } from 'react';
import Viewer3D from './viewer3d';
import State3D from './ruler-utils/state3D';
import {
  ReactSVGPanZoom,
  TOOL_NONE,
  TOOL_PAN,
  TOOL_ZOOM_IN,
  TOOL_ZOOM_OUT,
  TOOL_AUTO,
  zoom
} from 'react-svg-pan-zoom';
import * as constants from '../../constants';
import PropTypes, { element } from 'prop-types';
import { GeometryUtils } from '../../utils/export';
import convert from 'convert-units';

let pinFlag = false;
let sFlag = false; //for all object move
let wall_thickness = constants.LINE_THICKNESS / 2;

function mode2Tool(mode) {
  if (pinFlag) {
    return TOOL_NONE;
  }
  switch (mode) {
    case constants.MODE_2D_PAN:
      return TOOL_PAN;
    case constants.MODE_2D_ZOOM_IN:
      return TOOL_ZOOM_IN;
    case constants.MODE_2D_ZOOM_OUT:
      return TOOL_ZOOM_OUT;
    case constants.MODE_IDLE:
      return TOOL_AUTO;
    default:
      return TOOL_NONE;
  }
}

let k = 0;
export default function Front3D(
  { width, height, state, replaceCabinet, keyDownEnable },
  {
    viewer2DActions,
    linesActions,
    holesActions,
    verticesActions,
    itemsActions,
    areaActions,
    projectActions,
    catalog
  }
) {
  const [mode, setMode] = useState('');
  const [rulerEdit, setRulerEdit] = useState(null);
  const [rulerEditID, setRulerEditID] = useState(null);
  const Viewer = useRef(null);

  let sceneWidth = width;
  let sceneHeight = height;
  let rulerSize = 0; //px
  let { viewer2D, scene } = state;
  let layerID = scene.selectedLayer;

  useEffect(() => {
    // move viewer point to center
    let selectedLayer = state.scene.layers.get(state.scene.selectedLayer);
    const vertices = selectedLayer.vertices;

    if (vertices.isEmpty()) {
      return;
    }

    let bottomX = 0,
      bottomY = 100000,
      topX = 100000,
      topY = 0;

    vertices.forEach(vertex => {
      if (bottomX < vertex.x) bottomX = vertex.x;
      if (bottomY > vertex.y) bottomY = vertex.y;
      if (topX > vertex.x) topX = vertex.x;
      if (topY < vertex.y) topY = vertex.y;
    });

    let moveX = topX + (bottomX - topX) / 2;
    let moveY = 2000 - (bottomY + (topY - bottomY) / 2);

    Viewer.current.setPointOnViewerCenter(moveX, moveY, viewer2D.get('a'));
  }, []);

  if (mode !== state.get('mode')) {
    k++;
    setMode(state.get('mode'));
  }

  let onChangeValue = value => {
    if (sFlag) return;
    let _zoomValue = parseInt((value.a - 0.5) / constants.ZOOM_VARIABLE);
    if (_zoomValue > 404) return;
    if (_zoomValue < 0 || Number.isNaN(_zoomValue)) return;

    if (
      (rulerEdit !== null && value.startX && value.startY) ||
      value.lastAction === 'zoom'
    ) {
      let _rulerEdit = document.getElementById('ruler_numberInput'),
        _rect;
      if (rulerEditID !== null) {
        _rect = document.getElementById(rulerEditID);
      }
      let bbox;
      if (_rect && _rulerEdit) {
        bbox = _rect.getBoundingClientRect();
        // bbox.width = _rect.getBBox().width;
        // bbox.height = _rect.getBBox().height;
        _rulerEdit.style.left = `${bbox.left - (150 - bbox.width) / 2}px`;
        _rulerEdit.style.top = `${bbox.top - (50 - bbox.height) / 2}px`;
      }
    }

    if (
      value.e <= 10 &&
      value.e + value.a * value.SVGWidth + 10 >= value.viewerWidth &&
      value.f <= 80 &&
      value.f + value.d * value.SVGHeight + 10 >= value.viewerHeight
    ) {
      /*let val = Object.assign({}, value);
      if (refresh === 2) {
        val.a += 0.4; val.d += 0.4;
        val.e -= (val.SVGWidth * val.a - val.SVGWidth * (val.a - 0.4)) / 2;
        val.f -= (val.SVGHeight * val.d - val.SVGHeight * (val.d - 0.4)) / 2;
        console.log('----', val)
      }*/
      projectActions.updateZoomScale(value.a);
      return viewer2DActions.updateCameraView(value);
    }
  };

  let createLineGeometry = scene => {
    let layer = scene.getIn(['layers', layerID]);
    let lines = layer.lines;

    lines.toArray().forEach(line => {
      let vertex0Id = line.vertices.get(0);
      let vertex1Id = line.vertices.get(1);
      let { x: x0, y: y0 } = layer.vertices.get(vertex0Id);
      let { x: x1, y: y1 } = layer.vertices.get(vertex1Id);
      if (x0 == x1 && y0 == y1) return;

      let allLines = layer.lines.toArray();
      let relatedLineArray = [];
      let relatedLine0 = allLines.filter(allLine => {
        return (
          allLine.vertices.toArray().includes(vertex0Id) &&
          line.id != allLine.id
        );
      })[0];
      let relatedLine1 = allLines.filter(allLine => {
        return (
          allLine.vertices.toArray().includes(vertex1Id) &&
          line.id != allLine.id
        );
      })[0];

      let normalVertice = GeometryUtils.getNormaline(x0, y0, x1, y1);
      let rx0 = x0 + normalVertice.x * wall_thickness;
      let ry0 = y0 + normalVertice.y * wall_thickness;
      let rx1 = x1 + normalVertice.x * wall_thickness;
      let ry1 = y1 + normalVertice.y * wall_thickness;

      let intersection = [];

      relatedLine0
        ? relatedLineArray.push({ index: 0, line: relatedLine0 })
        : intersection.push({ index: 0, point: { x: rx0, y: ry0 } });
      relatedLine1
        ? relatedLineArray.push({ index: 1, line: relatedLine1 })
        : intersection.push({ index: 1, point: { x: rx1, y: ry1 } });

      let originalLineFunction = GeometryUtils.linePassingThroughTwoPoints(
        x0,
        y0,
        x1,
        y1
      );
      originalLineFunction.c -=
        Math.sqrt(
          originalLineFunction.b * originalLineFunction.b +
            originalLineFunction.a * originalLineFunction.a
        ) * wall_thickness;

      relatedLineArray.forEach(lineInfo => {
        let vertexId, originx, originy;
        let { index, line: relatedLine } = lineInfo;
        index == 0
          ? ((vertexId = vertex0Id), (originx = x0), (originy = y0))
          : ((vertexId = vertex1Id), (originx = x1), (originy = y1));

        let relatedVertices = relatedLine.vertices.toArray();
        let relatedOtherVertexID =
          relatedVertices[0] == vertexId
            ? relatedVertices[1]
            : relatedVertices[0];
        let relatedVertex = layer.getIn(['vertices', relatedOtherVertexID]);
        if (originx == relatedVertex.x && originy == relatedVertex.y) return;

        let relatedLineFunction = GeometryUtils.linePassingThroughTwoPoints(
          originx,
          originy,
          relatedVertex.x,
          relatedVertex.y
        );
        let delta = GeometryUtils.distancePointFromLine(
          relatedLineFunction.a,
          relatedLineFunction.b,
          relatedLineFunction.c,
          index == 0 ? x1 : x0,
          index == 0 ? y1 : y0
        );
        if (delta < 0.01) {
          // if the directions of the current line and related line, then no need to calculate the inteersection point
          let rx = originx + normalVertice.x * wall_thickness;
          let ry = originy + normalVertice.y * wall_thickness;
          intersection.push({ index, point: { x: rx, y: ry } });
        } else {
          relatedLineFunction.c -=
            (relatedVertices[0] == vertexId ? 1 : -1) *
            Math.sqrt(
              relatedLineFunction.b * relatedLineFunction.b +
                relatedLineFunction.a * relatedLineFunction.a
            ) *
            wall_thickness;
          let point = GeometryUtils.twoLinesIntersection(
            originalLineFunction.a,
            originalLineFunction.b,
            originalLineFunction.c,
            relatedLineFunction.a,
            relatedLineFunction.b,
            relatedLineFunction.c
          );
          point && intersection.push({ index, point });
        }
      });
      linesActions.setRelatedLine(layer.id, line.id, intersection);
    });
  };

  let mapCursorPosition = ({ x, y }) => {
    return { x, y: -y + scene.height };
  };

  let onMouseMove = viewerEvent => {
    createLineGeometry(scene);

    //workaround that allow imageful component to work

    let evt = new Event('mousemove-planner-event');
    evt.viewerEvent = viewerEvent;
    document.dispatchEvent(evt);
    let { x, y } = mapCursorPosition(viewerEvent);
    projectActions.updateMouseCoord({ x, y });
    if (sFlag) {
      let differs = { x: x - sPoint.x, y: y - sPoint.y };
      projectActions.selectAll(differs);
    } else
      switch (mode) {
        case constants.MODE_DRAWING_LINE:
          // Blocked 90 degree snap.
          // let prevVertexID = state.getIn(['scene', 'layers', layerID, 'selected', 'vertices']).toJS()[0];
          // let prevVertex = state.getIn(['scene', 'layers', layerID, 'vertices', prevVertexID]);
          // let dx = Math.abs(x - prevVertex.x);
          // let dy = Math.abs(y - prevVertex.y);
          // if (dx > dy) y = prevVertex.y
          // else x = prevVertex.x;
          linesActions.updateDrawingLine(x, y, state.snapMask);
          break;

        case constants.MODE_DRAWING_HOLE:
          holesActions.updateDrawingHole(layerID, x, y);
          break;

        case constants.MODE_DRAWING_ITEM:
          let layer = scene.layers.get(layerID);
          let flag = false;
          layer.items.some(item => {
            if (item.selected) {
              item.counterTop.uri = layer.counterTop.uri;
              current_sel_obj_id = item.id;
              flag = true;
            }
          });
          if (current_sel_obj_id === null || !flag) {
            itemsActions.updateDrawingItem(layerID, x, y);
            endPoint.x = x;
            endPoint.y = y;
          } else {
            prepareSnap();
            var { nx, ny, rot, rotRad } = GeometryUtils.calcSnap(
              allItemRect,
              allItemSnap,
              allLineRects,
              allLineSnap,
              allRect,
              x,
              y,
              allArea
            );
            let val = {
              pos: { x, y },
              rotRad,
              size: allItemRect.cur && allItemRect.cur.size,
              layoutpos: allItemRect.cur && allItemRect.cur.layoutpos,
              is_corner: allItemRect.cur && allItemRect.cur.is_corner
            };
            let { isSect, snap } = GeometryUtils.getAllHoleRect(scene, val);
            if (snap !== null && snap !== [] && isSect) {
              if (snap.length == 1) val.pos = { x: snap[0].x, y: snap[0].y };
              else {
                if (
                  (snap[0].x - x) * (snap[0].x - x) +
                    (snap[0].y - y) * (snap[0].y - y) <
                  (snap[1].x - x) * (snap[1].x - x) +
                    (snap[1].y - y) * (snap[1].y - y)
                )
                  val.pos = { x: snap[0].x, y: snap[0].y };
                else val.pos = { x: snap[1].x, y: snap[1].y };
              }
              let interSect = GeometryUtils.validInterSect(
                allItemRect.others,
                val
              );
              if (interSect) {
                nx = val.pos.x;
                ny = val.pos.y;
              }
            }

            val.pos = { x: nx, y: ny };
            let isrectSect = GeometryUtils.validInterSect(
              allItemRect.others,
              val
            );
            if (isrectSect && isSect) {
              itemsActions.updateDraggingItemChanged(
                nx,
                ny,
                layerID,
                current_sel_obj_id
              );
              itemsActions.updateRotatingItemChanged(
                rot,
                layerID,
                current_sel_obj_id
              );
              endPoint.x = nx;
              endPoint.y = ny;
            }
            if (
              (allItemRect.cur &&
                allItemRect.cur.itemInfo.name.includes('Cook Top')) ||
              (allItemRect.cur &&
                allItemRect.cur.itemInfo.name.includes('cabinet'))
            ) {
              itemsActions.updateDraggingItemChanged(
                nx,
                ny,
                layerID,
                current_sel_obj_id
              );
              itemsActions.updateRotatingItemChanged(
                rot,
                layerID,
                current_sel_obj_id
              );
              endPoint.x = nx;
              endPoint.y = ny;
            }
            if (
              (allItemRect.cur &&
                allItemRect.cur.itemInfo.name.includes('Hood')) ||
              ((allItemRect.cur &&
                allItemRect.cur.itemInfo.name.includes('Range')) ||
                (allItemRect.cur &&
                  allItemRect.cur.itemInfo.name.includes('Cook Top')))
            ) {
              itemsActions.updateDraggingItemChanged(
                nx,
                ny,
                layerID,
                current_sel_obj_id
              );
              itemsActions.updateRotatingItemChanged(
                rot,
                layerID,
                current_sel_obj_id
              );
              endPoint.x = nx;
              endPoint.y = ny;
            }
          }
          break;

        case constants.MODE_DRAGGING_HOLE:
          holesActions.updateDraggingHole(x, y);
          break;

        case constants.MODE_DRAGGING_LINE:
          linesActions.updateDraggingLine(x, y, state.snapMask);
          break;

        case constants.MODE_DRAGGING_VERTEX:
          verticesActions.updateDraggingVertex(x, y, state.snapMask);
          break;

        case constants.MODE_DRAGGING_ITEM:
          prepareSnap();
          var { nx, ny, rot, rotRad } = GeometryUtils.calcSnap(
            allItemRect,
            allItemSnap,
            allLineRects,
            allLineSnap,
            allRect,
            x,
            y,
            allArea
          );
          let val = {
            pos: { x, y },
            rotRad,
            size: allItemRect.cur && allItemRect.cur.size,
            layoutpos: allItemRect.cur && allItemRect.cur.layoutpos,
            is_corner: allItemRect.cur && allItemRect.cur.is_corner
          };
          let { isSect, snap } = GeometryUtils.getAllHoleRect(scene, val);
          if (snap !== null && snap !== [] && isSect) {
            if (snap.length == 1) val.pos = { x: snap[0].x, y: snap[0].y };
            else if (snap.length == 2) {
              if (
                (snap[0].x - x) * (snap[0].x - x) +
                  (snap[0].y - y) * (snap[0].y - y) <
                (snap[1].x - x) * (snap[1].x - x) +
                  (snap[1].y - y) * (snap[1].y - y)
              )
                val.pos = { x: snap[0].x, y: snap[0].y };
              else val.pos = { x: snap[1].x, y: snap[1].y };
            }
            let interSect = GeometryUtils.validInterSect(
              allItemRect.others,
              val
            );
            if (interSect) {
              nx = val.pos.x;
              ny = val.pos.y;
            }
          }

          val.pos = { x: nx, y: ny };
          let isrectSect = GeometryUtils.validInterSect(
            allItemRect.others,
            val
          );
          if (isrectSect && isSect) {
            itemsActions.updateDraggingItemChanged(
              nx,
              ny,
              layerID,
              current_sel_obj_id
            );
            itemsActions.updateRotatingItemChanged(
              rot,
              layerID,
              current_sel_obj_id
            );
          }
          if (
            (allItemRect.cur &&
              allItemRect.cur.itemInfo.name.includes('Cook Top')) ||
            (allItemRect.cur &&
              allItemRect.cur.itemInfo.name.includes('Cabinet'))
          ) {
            itemsActions.updateDraggingItemChanged(
              nx,
              ny,
              layerID,
              current_sel_obj_id
            );
            itemsActions.updateRotatingItemChanged(
              rot,
              layerID,
              current_sel_obj_id
            );
          }
          if (
            (allItemRect.cur &&
              allItemRect.cur.itemInfo.name.includes('Hood')) ||
            ((allItemRect.cur &&
              allItemRect.cur.itemInfo.name.includes('Range')) ||
              (allItemRect.cur &&
                allItemRect.cur.itemInfo.name.includes('Cook Top')))
          ) {
            itemsActions.updateDraggingItemChanged(
              nx,
              ny,
              layerID,
              current_sel_obj_id
            );
            itemsActions.updateRotatingItemChanged(
              rot,
              layerID,
              current_sel_obj_id
            );
          }
          break;

        case constants.MODE_ROTATING_ITEM:
          itemsActions.updateRotatingItem(x, y);
          break;
      }

    viewerEvent.originalEvent.stopPropagation();
  };

  let onMouseDown = viewerEvent => {
    let event = viewerEvent.originalEvent;
    //workaround that allow imageful component to work
    let evt = new Event('mousedown-planner-event');
    evt.viewerEvent = viewerEvent;
    document.dispatchEvent(evt);
    createLineGeometry(scene);
    let { x, y } = mapCursorPosition(viewerEvent);
    let layer = state.scene.getIn(['layers', state.scene.selectedLayer]);
    let sCount =
      layer.selected.areas.size +
      layer.selected.holes.size +
      layer.selected.items.size +
      layer.selected.lines.size;
    if (mode === constants.MODE_DRAWING_LINE) {
      if (event.nativeEvent.which === 3) {
        projectActions.rollback();
        event.stopPropagation();
        return;
      }
    }
    if (mode === constants.MODE_IDLE) {
      let elementData = extractElementData(event.target);
      if (!elementData) return;

      if (sCount < 2)
        switch (elementData.prototype) {
          case 'lines':
            if (elementData.selected) {
              if (elementData.part === 'remove') break;
              linesActions.beginDraggingLine(
                elementData.layer,
                elementData.id,
                x,
                y,
                state.snapMask
              );
            }
            break;
          case 'vertices':
            verticesActions.beginDraggingVertex(
              elementData.layer,
              elementData.id,
              x,
              y,
              state.snapMask
            );
            break;

          case 'items':
            current_sel_obj_id = elementData.id;
            if (elementData.part === 'rotation-anchor')
              itemsActions.beginRotatingItem(
                elementData.layer,
                elementData.id,
                x,
                y
              );
            else if (elementData.part === 'remove') break;
            else if (elementData.part === 'duplicate') break;
            else if (elementData.part === 'warning_edit') break;
            else {
              // closes the setting dialog
              document.getElementById('setting_dialog').style.display = 'none';

              itemsActions.selectItem(elementData.layer, elementData.id);
              // projectActions.setMode(constants.MODE_DRAGGING_ITEM);
              itemsActions.beginDraggingItem(
                elementData.layer,
                elementData.id,
                x,
                y
              );
              replaceCabinet(false);
            }
            break;

          case 'holes':
            if (elementData.selected)
              holesActions.beginDraggingHole(
                elementData.layer,
                elementData.id,
                x,
                y
              );
            break;

          default:
            break;
        }
      else {
        sPoint.x = x;
        sPoint.y = y;
        sFlag = true;
      }
    }
    event.stopPropagation();
  };

  let onMouseUp = viewerEvent => {
    //set move all flag false
    sFlag = false;
    // //////////////////////
    // setRulerEdit(null);
    let event = viewerEvent.originalEvent;
    let bbox = event.target.getBoundingClientRect();
    // bbox.width = event.target.getBBox().width;
    // bbox.height = event.target.getBBox().height;
    if (event.target.tagName === 'rect') {
      if (event.target.id) {
        setRulerEditID(event.target.id);
      }
    }
    setRulerEdit(null);
    let evt = new Event('mouseup-planner-event');
    evt.viewerEvent = viewerEvent;
    document.dispatchEvent(evt);

    createLineGeometry(scene);
    let { x, y } = mapCursorPosition(viewerEvent);
    switch (mode) {
      case constants.MODE_IDLE:
        let elementData = extractElementData(event.target);
        // if (elementData && elementData.selected) return;
        switch (elementData ? elementData.prototype : 'none') {
          case 'areas':
            areaActions.selectArea(elementData.layer, elementData.id);
            break;

          case 'lines':
            if (elementData.part === 'remove') {
              projectActions.remove();
              break;
            } else {
              linesActions.selectLine(elementData.layer, elementData.id);
              break;
            }

          case 'holes':
            holesActions.selectHole(elementData.layer, elementData.id);
            break;

          case 'items':
            if (elementData.part === 'duplicate') {
              let currentObject = state.getIn([
                'scene',
                'layers',
                layerID,
                'items',
                elementData.id
              ]);
              itemsActions.duplicateSelected(currentObject);
              break;
            } else if (elementData.part === 'remove') {
              projectActions.remove();
              break;
            } else if (elementData.part === 'warning_edit') {
              // closes the setting dialog
              document.getElementById('setting_dialog').style.display = 'none';

              itemsActions.selectItem(elementData.layer, elementData.id);
              replaceCabinet(true);
              break;
            } else {
              projectActions.unselectAll();
              break;
            }
          case 'rulerDist':
            let _length1 = convert(elementData.length)
              .from(scene.unit)
              .to(scene.rulerUnit);
            let distanceText1 = `${_length1.toFixed(2)}`;
            const numberInput1 = (
              <div
                id="ruler_numberInput"
                style={{
                  position: 'absolute',
                  left: bbox.left - (150 - bbox.width) / 2,
                  top: bbox.top - (50 - bbox.height) / 2,
                  zIndex: 1000
                }}
              >
                <FormNumberInput
                  style={{
                    width: 150,
                    height: 50,
                    textAlign: 'center',
                    paddingRight: 10,
                    fontSize: '16px',
                    lineHeight: '22px',
                    fontWeight: 600
                  }}
                  value={distanceText1}
                  onChange={event => {
                    const value = new Map({
                      length: convert(event.target.value)
                        .from(scene.rulerUnit)
                        .to(scene.unit),
                      _length: event.target.value,
                      _unit: scene.rulerUnit
                    });
                    updateRulerDistAttribute(elementData, value);
                  }}
                  precision={2}
                />
              </div>
            );
            setRulerEdit(numberInput1);
            projectActions.unselectAll();
            break;
          case 'ruler':
            let _length = convert(elementData.length)
              .from(scene.unit)
              .to(scene.rulerUnit);
            let distanceText = `${_length.toFixed(2)}`;
            const numberInput = (
              <div
                id="ruler_numberInput"
                style={{
                  position: 'absolute',
                  left: bbox.left - (150 - bbox.width) / 2,
                  top: bbox.top - (50 - bbox.height) / 2,
                  zIndex: 1000
                }}
              >
                <FormNumberInput
                  style={{
                    width: 150,
                    height: 50,
                    textAlign: 'center',
                    paddingRight: 10,
                    fontSize: '16px',
                    lineHeight: '22px',
                    fontWeight: 600
                  }}
                  value={distanceText}
                  onChange={event => {
                    const value = new Map({
                      length: convert(event.target.value)
                        .from(scene.rulerUnit)
                        .to(scene.unit),
                      _length: event.target.value,
                      _unit: scene.rulerUnit
                    });
                    updateRulerAttribute(elementData, value);
                  }}
                  precision={2}
                />
              </div>
            );
            setRulerEdit(numberInput);
            projectActions.unselectAll();
            break;
          case 'twoHoleRuler':
            let _lengthTwoHoleRuler = convert(elementData.length)
              .from(scene.unit)
              .to(scene.rulerUnit);
            let distanceTextTwoHoleRuler = `${_lengthTwoHoleRuler.toFixed(2)}`;
            const numberInputTwoHoleRuler = (
              <div
                id="ruler_numberInput"
                style={{
                  position: 'absolute',
                  left: bbox.left - (150 - bbox.width) / 2,
                  top: bbox.top - (50 - bbox.height) / 2,
                  zIndex: 1000
                }}
              >
                <FormNumberInput
                  style={{
                    width: 150,
                    height: 50,
                    textAlign: 'center',
                    padding: 'auto',
                    fontSize: '16px',
                    lineHeight: '22px',
                    fontWeight: 600
                  }}
                  value={distanceTextTwoHoleRuler}
                  onChange={event => {
                    const value = new Map({
                      length: convert(event.target.value / 2)
                        .from(scene.rulerUnit)
                        .to(scene.unit),
                      _length: event.target.value / 2,
                      _unit: scene.rulerUnit
                    });
                    updateTwoHoleRulerAttribute(elementData, value);
                  }}
                  precision={2}
                />
              </div>
            );
            setRulerEdit(numberInputTwoHoleRuler);
            projectActions.unselectAll();
            break;
          case 'leftHoleRuler':
            let _lengthLeftHoleRuler = convert(elementData.length)
              .from(scene.unit)
              .to(scene.rulerUnit);
            let distanceTextLeftHoleRuler = `${_lengthLeftHoleRuler.toFixed(
              2
            )}`;
            const numberInputLeftHoleRuler = (
              <div
                id="ruler_numberInput"
                style={{
                  position: 'absolute',
                  left: bbox.left - (150 - bbox.width) / 2,
                  top: bbox.top - (50 - bbox.height) / 2,
                  zIndex: 1000
                }}
              >
                <FormNumberInput
                  style={{
                    width: 150,
                    height: 50,
                    textAlign: 'center',
                    paddingRight: 10,
                    fontSize: '16px',
                    lineHeight: '22px',
                    fontWeight: 600
                  }}
                  value={distanceTextLeftHoleRuler}
                  onChange={event => {
                    const value = new Map({
                      length: convert(event.target.value)
                        .from(scene.rulerUnit)
                        .to(scene.unit),
                      _length: event.target.value,
                      _unit: scene.rulerUnit
                    });
                    updateLeftHoleRulerAttribute(elementData, value);
                  }}
                  precision={2}
                />
              </div>
            );
            setRulerEdit(numberInputLeftHoleRuler);
            projectActions.unselectAll();
            break;
          case 'rulerHole':
            let _lengthRulerHole = convert(elementData.length)
              .from(scene.unit)
              .to(scene.rulerUnit);
            let distanceTextRulerHole = `${_lengthRulerHole.toFixed(2)}`;
            const numberInputRulerHole = (
              <div
                id="ruler_numberInput"
                style={{
                  position: 'absolute',
                  left: bbox.left - (150 - bbox.width) / 2,
                  top: bbox.top - (50 - bbox.height) / 2,
                  zIndex: 1000
                }}
              >
                <FormNumberInput
                  style={{
                    width: 150,
                    height: 50,
                    textAlign: 'center',
                    paddingRight: 10,
                    fontSize: '16px',
                    lineHeight: '22px',
                    fontWeight: 600
                  }}
                  value={distanceTextRulerHole}
                  onChange={event => {
                    const value = new Map({
                      length: convert(event.target.value)
                        .from(scene.rulerUnit)
                        .to(scene.unit),
                      _length: event.target.value,
                      _unit: scene.rulerUnit
                    });
                    updateHoleRulerAttribute(elementData, value);
                  }}
                  precision={2}
                />
              </div>
            );
            setRulerEdit(numberInputRulerHole);
            projectActions.unselectAll();
            break;
          case 'rightHoleRuler':
            let _lengthRightHoleRuler = convert(elementData.length)
              .from(scene.unit)
              .to(scene.rulerUnit);
            let distanceTextRightHoleRuler = `${_lengthRightHoleRuler.toFixed(
              2
            )}`;
            const numberInputRightHoleRuler = (
              <div
                id="ruler_numberInput"
                style={{
                  position: 'absolute',
                  left: bbox.left - (150 - bbox.width) / 2,
                  top: bbox.top - (50 - bbox.height) / 2,
                  zIndex: 1000
                }}
              >
                <FormNumberInput
                  style={{
                    width: 150,
                    height: 50,
                    textAlign: 'center',
                    paddingRight: 10,
                    fontSize: '16px',
                    lineHeight: '22px',
                    fontWeight: 600
                  }}
                  value={distanceTextRightHoleRuler}
                  onChange={event => {
                    const value = new Map({
                      length: convert(event.target.value)
                        .from(scene.rulerUnit)
                        .to(scene.unit),
                      _length: event.target.value,
                      _unit: scene.rulerUnit
                    });
                    updateRightHoleRulerAttribute(elementData, value);
                  }}
                  precision={2}
                />
              </div>
            );
            setRulerEdit(numberInputRightHoleRuler);
            projectActions.unselectAll();
            break;
          case 'angleChange':
            let _length2 = elementData.length;
            const numberInput2 = (
              <div
                id="ruler_numberInput"
                style={{
                  position: 'absolute',
                  left: bbox.left - (150 - bbox.width) / 2,
                  top: bbox.top - (50 - bbox.height) / 2,
                  zIndex: 1000
                }}
              >
                <FormNumberInput
                  style={{
                    width: 50,
                    height: 50
                  }}
                  value={_length2}
                  onChange={event => {
                    const value = new Map({
                      length: convert(event.target.value)
                        .from(scene.rulerUnit)
                        .to(scene.unit),
                      _length: event.target.value,
                      _unit: scene.rulerUnit
                    });
                    updateangleChangeAttribute(elementData, value);
                  }}
                  precision={2}
                />
              </div>
            );
            setRulerEdit(numberInput2);
            projectActions.unselectAll();
            break;
          case 'none':
            projectActions.unselectAll();
            break;
        }
        break;

      case constants.MODE_WAITING_DRAWING_LINE:
        linesActions.beginDrawingLine(layerID, x, y, state.snapMask);
        break;

      case constants.MODE_DRAWING_LINE:
        // Blocked 90 degree snap.
        // let prevVertexID = state.getIn(['scene', 'layers', layerID, 'selected', 'vertices']).toJS()[0];
        // let prevVertex = state.getIn(['scene', 'layers', layerID, 'vertices', prevVertexID]);
        // let dx = Math.abs(x - prevVertex.x);
        // let dy = Math.abs(y - prevVertex.y);
        // if (dx > dy) y = prevVertex.y
        // else x = prevVertex.x;
        linesActions.endDrawingLine(x, y, state.snapMask);
        linesActions.beginDrawingLine(layerID, x, y, state.snapMask);
        break;

      case constants.MODE_DRAWING_HOLE:
        holesActions.endDrawingHole(layerID, x, y);
        break;

      case constants.MODE_DRAWING_ITEM:
        itemsActions.endDrawingItem(layerID, endPoint.x, endPoint.y);
        break;

      case constants.MODE_DRAGGING_LINE:
        linesActions.endDraggingLine(x, y, state.snapMask);
        break;

      case constants.MODE_DRAGGING_VERTEX:
        verticesActions.endDraggingVertex(x, y, state.snapMask);
        break;

      case constants.MODE_DRAGGING_ITEM:
        projectActions.setMode(MODE_IDLE);
        break;

      case constants.MODE_DRAGGING_HOLE:
        holesActions.endDraggingHole(x, y);
        break;

      case constants.MODE_ROTATING_ITEM:
        itemsActions.endRotatingItem(x, y);
        break;
    }

    event.stopPropagation();
  };

  function mode2DetectAutopan(mode) {
    switch (mode) {
      case constants.MODE_DRAWING_LINE:
      case constants.MODE_DRAGGING_LINE:
      case constants.MODE_DRAGGING_VERTEX:
      case constants.MODE_DRAGGING_HOLE:
      case constants.MODE_DRAGGING_ITEM:
      case constants.MODE_DRAWING_HOLE:
      case constants.MODE_DRAWING_ITEM:
        return true;

      default:
        return false;
    }
  }

  let onChangeTool = tool => {
    switch (tool) {
      case TOOL_NONE:
        projectActions.selectToolEdit();
        break;

      case TOOL_PAN:
        viewer2DActions.selectToolPan();
        break;

      case TOOL_ZOOM_IN:
        viewer2DActions.selectToolZoomIn();
        break;

      case TOOL_ZOOM_OUT:
        viewer2DActions.selectToolZoomOut();
        break;
    }
  };
  let { layers } = state.scene;
  let selectedLayer = layers.get(state.scene.selectedLayer);
  let ceilHeight = selectedLayer.ceilHeight;
  let vertices = selectedLayer.vertices.toJS();
  let maxX = 0,
    maxY = 0,
    minX = 0,
    minY = 0;
  for (let vertex in vertices) {
    maxX = Math.max(vertices[vertex].x, maxX);
    maxY = Math.max(vertices[vertex].y, maxY);
    if (minX === 0) minX = vertices[vertex].x;
    if (minY === 0) minY = vertices[vertex].y;
    minX = Math.min(vertices[vertex].x, minX);
    minY = Math.min(vertices[vertex].y, minY);
  }
  let line_length = 0;
  switch (mode) {
    case constants.MODE_FRONT_ELEVATION_VIEW:
    case constants.MODE_BACK_ELEVATION_VIEW:
      line_length = maxX - minX;
      break;
    case constants.MODE_RIGHT_ELEVATION_VIEW:
    case constants.MODE_LEFT_ELEVATION_VIEW:
      line_length = maxY - minY;
      break;
  }
  line_length = convert(line_length)
    .from(state.scene.unit)
    .to(state.scene.rulerUnit);
  let scale = Math.min(
    (width * 0.6) / line_length,
    (height * 0.6) / ceilHeight
  );
  let frontRect = { width: line_length * scale, height: ceilHeight * scale };

  return (
    <>
      <Viewer3D
        id = "viewer3D"
        key={k}
        state={state}
        width={frontRect.width * viewer2D.toJS().a}
        height={frontRect.height * viewer2D.toJS().d}
        replaceCabinet={replaceCabinet}
        keyDownEnable={keyDownEnable}
        transform={`translate(${viewer2D.toJS().e + (width - frontRect.width) * viewer2D.toJS().a / 2}px, ${viewer2D.toJS().f + (height - frontRect.height) * viewer2D.toJS().d / 2}px)`}
      />
      <ReactSVGPanZoom
        style={{ gridColumn: 2, gridRow: 2, position: 'absolute' }}
        width={width - rulerSize}
        height={height - rulerSize}
        value={viewer2D.isEmpty() ? null : viewer2D.toJS()}
        onChangeValue={onChangeValue}
        tool={mode2Tool(mode)}
        onChangeTool={onChangeTool}
        detectAutoPan={mode2DetectAutopan(mode)}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        miniaturePosition="none"
        toolbarPosition="none"
        detectPinchGesture={false}
        disableDoubleClickZoomWithToolAuto={true}
        ref={Viewer}
      >
        <svg width={sceneWidth} height={sceneHeight}>
          <g
            transform={`translate(${width / 2}, ${height / 2})`}
            id="front"
            style={{ position: 'absolute', display: 'block' }}
          >
            <State3D
              state={state}
              catalog={catalog}
              viewer2DActions={viewer2DActions}
              height={sceneHeight}
              width={sceneWidth}
              frontRect={frontRect}
              selectedLayer={selectedLayer}
              line_length={line_length}
              ceilHeight={ceilHeight}
              scale={scale}
              minX={minX}
              minY={minY}
              maxX={maxX}
              maxY={maxY}
            />
          </g>
        </svg>
      </ReactSVGPanZoom>
    </>
  );
}

Front3D.propTypes = {
  state: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
};

Front3D.contextTypes = {
  viewer2DActions: PropTypes.object.isRequired,
  linesActions: PropTypes.object.isRequired,
  holesActions: PropTypes.object.isRequired,
  verticesActions: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  areaActions: PropTypes.object.isRequired,
  projectActions: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired
};
