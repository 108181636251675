import React from 'react';
import * as S from './styles';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
import ReviewItem from '../product/reviewItem';
import {
  SECONDARY_PURPLE_COLOR,
  TEXT_COLOR_NEUTRAL_2,
  TEXT_COLOR_NEUTRAL_5,
  BG_COLOR_1
} from '../../../../constants';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '../../main/styles';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    border-radius: 10px;
    max-width: unset;
    width: 900px;
    height : 90%;
    /* padding: 30px; */
  }
  ::-webkit-scrollbar {
    width: 2px;
    border-radius: 2px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track-piece {
      background-color: #fff;
      border-radius: 1px;
  }
`;



const StyledMuiCheckbox = withStyles({
  root: {
    color: TEXT_COLOR_NEUTRAL_2,
    '&$checked': {
      color: SECONDARY_PURPLE_COLOR
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

class DownloadSummaryPopup extends React.Component {
  constructor(props) {
    super(props);

    // console.log(props)
    // let layer = props.layers.get(props.selectedLayer);
    // let elements = {
    //   lines: layer.lines,
    //   holes: layer.holes,
    //   items: layer.items,
    //   molding: layer.molding
    // };
    // let ceilingHeight = props.state.scene.layers.get(props.selectedLayer).ceilHeight;
    // // let measurementUnit = unitData.filter(unit => unit._unit === props.state.scene.layers.get(props.selectedLayer).unit)[0];

    this.state = {
      // elements : elements,
      // measurementUnit : measurementUnit,
      email: sessionStorage.getItem('email') || '',
      emailfocusOn: false,
      phone: sessionStorage.getItem('phone') || '',
      phonefocusOn: false,
      firstName: sessionStorage.getItem('firstName') || '',
      firstNamefocusOn: false,
      lastName: sessionStorage.getItem('lastName') || '',
      lastNamefocusOn: false,
      projectTitlefocusOn: false,
      projectTitle: sessionStorage.getItem('projectTitle'),
      question1: false,
      question2: false,
      question3: false,
      image : ''
    };
  }

  _closePanel() {
    this.props.setDownloadPopupVisible(false);
  }

  


  render() {
    const { visible } = this.props;
    const {
      firstName,
      firstNameFocusOn,
      lastName,
      lastNameFocusOn,
      email,
      emailFocusOn,
      phone,
      phoneFocusOn,
      projectTitle,
      projectTitleFocusOn,
      question1,
      question2,
      question3,
      image
    } = this.state;

  // let canvas = document.getElementById('viewer3D');
  
  // let heightAtt = canvas.height;
  
  // let widthAtt = canvas.width;
 
  // let img = new Image ();
  // img.crossOrigin = 'anonymous';
  // img.src = canvas.toDataURL();
  
  // img.onload = () => {
  //   ctx.drawImage(img, 0, 0, widthAtt, heightAtt);
  //   this.setState({
  //     image: img
  //   });
  // };

    const preview3D = `
<!DOCTYPE html>
<html>
  <head>
    <style>
      * {
        box-sizing: border-box;
      }
      body {
        margin: auto;
      }
      .main{
        border-radius : 5px;
      }
      .container {
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color : white;
        border-bottom-left-radius : 5px;
        border-bottom-right-radius : 5px;
      }
      .adImgStyle {
        width: 100%;
        margin: auto;
        border-top-left-radius : 5px;
        border-top-right-radius : 5px;
      }
      .baseFontStyle {
        color: rgba(10, 10, 10, 0.692);
        /* font-size: 12px; */
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 10px;
        font-family: sans-serif;
        /* font-weight: 600; */
      }
      .headerStyle {
        margin-top: 20px;
        padding: 15px 0;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid gray;
      }
      .footerStyle {
        padding: 15px 0;
        border-top: 1px solid gray;
        display: flex;
        justify-content: space-between;
        font-family: sans-serif;
        color: #777777;
      }
      .contentStyle {
        padding: 30px 0;
      }
      .summaryStore {
        display: flex;
        justify-content: space-between;
        padding-bottom: 50px;
        font-family: sans-serif;
      }
      .summaryStyle {
        font-weight: 600;
      }
      .logoStyle {
        width: 60px;
        height: 60px;
        background-color: steelblue;
      }
      .dividerStyle {
        padding: 0 5px;
      }
    </style>
  </head>
  <body>
  <div class = "main">
  <div >
    <img class="adImgStyle" src="/assets/rtastore_logo.png" alt="" />
  </div>
    <div class="container">
      <div class="headerStyle">
        <div class="headerShapeStyle">
          <span class="baseFontStyle">Created for : </span>
          <span class="baseFontStyle">${sessionStorage.getItem('firstName') + ' ' + sessionStorage.getItem('lastName')}</span>
        </div>
        <div>
          <span class="baseFontStyle">Date : </span>
          <span class="baseFontStyle">04/02/2024</span>
        </div>
        <div>
          <span class="baseFontStyle">ID : </span>
          <span class="baseFontStyle">${sessionStorage.getItem('projectID')}</span>
        </div>
      </div>
      <div class="contentStyle">
        <div class="summaryStore">
          <div class="summaryStyle">
            <h1>${sessionStorage.getItem('projectTitle')}</h1>
            <div>
              <span>Summary ID:</span> <span> ${sessionStorage.getItem('projectID')}/02/2024 </span>
              <span class="dividerStyle"> | </span>
              <span> Available: </span> <span>14/02/2024</span>
            </div>
          </div>
          <div class="logoStyle">
            <!-- RTA -->
            <img style = {width="100%" height="100%"} src="/assets/rtastore_logo.png" alt="RTA logo">
          </div>
        </div>
        <div class="contentContainer">
          <b>DisClaimer:</b>DIY Design Space not take resposibility for the
          accuracy of measurements or furniture arrangements. The prices
          displayed here apply to items in stock at the time of the offer's
          issuance. All delivery, assembly, and installation services required
          are billed are billed separately and are not included in the price.
          While we strive to ensure the accuracy of the information in this
          program, we apologize for any errors that may occur.'
        </div>
      </div>
      <div class="footerStyle">
        <span>＠ DIY.DS v1.0/2024</span>
        <span>DIY Design Space 2024 All rights reserved</span>
      </div>
    </div>
    </div>
  </body>
</html>

`;

const preview2D = `
<!DOCTYPE html>
<html>

<head>
  <style>
    * {
      box-sizing: border-box;
    }

    body {
      width: 100%;
      margin: auto;
    }

    .container {
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius : 5px;
    }

    .adImgStyle {
      width: 100%;
      margin: auto;
    }

    .baseFontStyle {
      color: rgba(10, 10, 10, 0.692);
      /* font-size: 12px; */
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 10px;
      font-family: sans-serif;
      /* font-weight: 600; */
    }

    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      border-bottom: 1px solid #878787;
    }
    .logo-container {
      flex: 2;
      display: flex;
      align-items: center;
    }
    .logo-img {
      height: 40px;
      width: 40px;
      background-color: blueviolet;
    }
    .logo-title {
      padding: 0 10px;
    }
    .info-container {
      flex: 3;
      display: flex;
      justify-content: space-between;
      font-family: sans-serif;
      color: #777777;
    }

    .headerStyle {
      margin-top: 20px;
      padding: 15px 0;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid gray;
    }

    .footerStyle {
      padding: 15px 0;
      border-top: 1px solid gray;
      display: flex;
      justify-content: space-between;
      font-family: sans-serif;
      color: #777777;
    }

    .contentStyle {
      padding: 30px 0;
    }

    .summaryStore {
      display: flex;
      justify-content: space-between;
      font-family: sans-serif;
    }

    .summaryStyle {
      font-weight: 600;
    }

    .logoStyle {
      width: 50px;
      height: 50px;
      background-color: steelblue;
    }
  </style>
</head>

<body>
  <div class="container">
    <div class="header-container">
      <div class="logo-container">
        <div class="logo-img">
          <img src="/assets/rtastore_logo.png" width="100%" height="100%" />
        </div>
        <div class="logo-title">${sessionStorage.getItem('projectTitle')}</div>
      </div>
      <div class="info-container">
        <div class="info-style">Created for: ${sessionStorage.getItem('firstName') + ' ' + sessionStorage.getItem('lastName')}</div>
        <div class="info-style">Date: 04/02/2024</div>
        <div class="info-style">ID: ${sessionStorage.getItem('projectID')}</div>
      </div>
    </div>
    <div class="contentStyle">
      <div class="summaryStore">
        <div class="summaryStyle">
          <h1>2D Floor Plan</h1>
          <div>
            <span> Technical view </span>
          </div>
        </div>

      </div>
      <img class="adImgStyle" src="/assets/a.png" alt="" />
      <div class="contentContainer">
        <b>DisClaimer:</b>DIY Design Space not take resposibility for the
        accuracy of measurements or furniture arrangements. The prices
        displayed here apply to items in stock at the time of the offer's
        issuance. All delivery, assembly, and installation services required
        are billed are billed separately and are not included in the price.
        While we strive to ensure the accuracy of the information in this
        program, we apologize for any errors that may occur.'
      </div>
    </div>
    <div class="footerStyle">
      <span>＠ DIY.DS v1.0/2024</span>
      <span>DIY Design Space 2024 All rights reserved</span>
    </div>
  </div>
</body>

</html>
`

const elevationView = `
<!DOCTYPE html>
<html>

<head>
  <style>
    * {
      box-sizing: border-box;
    }

    body {
      width: 100%;
      margin: auto;
    }

    .container {
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      border-bottom: 1px solid #878787;
    }
    .logo-container {
      flex: 2;
      display: flex;
      align-items: center;
    }
    .logo-img {
      height: 40px;
      width: 40px;
      background-color: blueviolet;
    }
    .logo-title {
      padding: 0 10px;
    }
    .info-container {
      flex: 3;
      display: flex;
      justify-content: space-between;
      font-family: sans-serif;
      color: #777777;
    }

    .adImgStyle {
      width: 100%;
      margin: auto;
    }

    .baseFontStyle {
      color: rgba(10, 10, 10, 0.692);
      /* font-size: 12px; */
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 10px;
      font-family: sans-serif;
      /* font-weight: 600; */
    }

    .headerStyle {
      margin-top: 20px;
      padding: 15px 0;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid gray;
    }

    .footerStyle {
      padding: 15px 0;
      border-top: 1px solid gray;
      display: flex;
      justify-content: space-between;
      font-family: sans-serif;
      color: #777777;
    }

    .contentStyle {
      padding: 30px 0;
    }

    .summaryStore {
      display: flex;
      justify-content: space-between;
      font-family: sans-serif;
    }

    .summaryStyle {
      font-weight: 600;
    }

    .logoStyle {
      width: 50px;
      height: 50px;
      background-color: steelblue;
    }
  </style>
</head>

<body>
  <div class="container">
    <div class="header-container">
      <div class="logo-container">
        <div class="logo-img">
          <img src="/assets/rtastore_logo.png" width="100%" height="100%" />
        </div>
        <div class="logo-title">${sessionStorage.getItem('projectTitle')}</div>
      </div>
      <div class="info-container">
        <div class="info-style">Created for: ${sessionStorage.getItem('firstName') + ' ' + sessionStorage.getItem('lastName')}</div>
        <div class="info-style">Date: 04/02/2024</div>
        <div class="info-style">ID: ${sessionStorage.getItem('projectID')}</div>
      </div>
    </div>
    <div class="contentStyle">
      <div class="summaryStore">
        <div class="summaryStyle">
          <h1>Elevation View</h1>
          <div>
            <span> Technical view </span>
          </div>
        </div>

      </div>
      <img class="adImgStyle" src="/assets/a.png" alt="" />
      <div class="contentContainer">
        <b>DisClaimer:</b>DIY Design Space not take resposibility for the
        accuracy of measurements or furniture arrangements. The prices
        displayed here apply to items in stock at the time of the offer's
        issuance. All delivery, assembly, and installation services required
        are billed are billed separately and are not included in the price.
        While we strive to ensure the accuracy of the information in this
        program, we apologize for any errors that may occur.'
      </div>
    </div>
    <div class="footerStyle">
      <span>＠ DIY.DS v1.0/2024</span>
      <span>DIY Design Space 2024 All rights reserved</span>
    </div>
  </div>
</body>

</html>
`

const warranty = `
<!DOCTYPE html>
<html>

<head>
  <style>
    * {
      box-sizing: border-box;
    }

    body {
      width: 100%;
      margin: auto;
    }

    .container {
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .adImgStyle {
      width: 100%;
      margin: auto;
    }

    .baseFontStyle {
      color: rgba(10, 10, 10, 0.692);
      /* font-size: 12px; */
      font-family: sans-serif;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 10px;
      /* font-weight: 600; */
    }
    .headerStyle {
      margin-top: 20px;
      padding: 15px 0;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid gray;
    }

    .footerStyle {
      padding: 15px 0;
      border-top: 1px solid gray;
      display: flex;
      justify-content: space-between;
      font-family: sans-serif;
      color: #777777;
    }

    .contentStyle {
      padding: 30px 0;
    }

    .summaryStore {
      display: flex;
      justify-content: space-between;
      font-family: sans-serif;
      margin-bottom: 30px;
    }

    .summaryStyle {
      font-weight: 600;
    }

    .logoStyle {
      width: 50px;
      height: 50px;
      background-color: steelblue;
    }

    .contentContainer {
      margin-bottom: 20px;
    }

    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      border-bottom: 1px solid #878787;
    }
    .logo-container {
      flex: 2;
      display: flex;
      align-items: center;
    }
    .logo-img {
      height: 40px;
      width: 40px;
      background-color: blueviolet;
    }
    .logo-title {
      padding: 0 10px;
    }
    .info-container {
      flex: 3;
      display: flex;
      justify-content: space-between;
      font-family: sans-serif;
      color: #777777;
    }
    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      border-bottom: 1px solid #878787;
    }
    .logo-container {
      flex: 2;
      display: flex;
      align-items: center;
    }
    .logo-img {
      height: 40px;
      width: 40px;
      background-color: blueviolet;
    }
    .logo-title {
      padding: 0 10px;
    }
    .info-container {
      flex: 3;
      display: flex;
      justify-content: space-between;
      font-family: sans-serif;
      color: #777777;
    }
  </style>
</head>

<body>
  <div class="container">
    <div class="header-container">
      <div class="logo-container">
        <div class="logo-img">
          <img src="/assets/rtastore_logo.png" width="100%" height="100%" />
        </div>
        <div class="logo-title">${sessionStorage.getItem('projectTitle')}</div>
      </div>
      <div class="info-container">
        <div class="info-style">Created for: ${sessionStorage.getItem('firstName') + ' ' + sessionStorage.getItem('lastName')}</div>
        <div class="info-style">Date: 04/02/2024</div>
        <div class="info-style">ID: ${sessionStorage.getItem('projectID')}</div>
      </div>
    </div>
    <div class="contentStyle">
      <div class="summaryStore">
        <div class="summaryStyle">
          <h1>Warranty</h1>
          <div>
            <span> Warranty and customer support </span>
          </div>
        </div>
      </div>
      <!-- <img class="adImgStyle" src="/assets/a.png" alt="" /> -->
      <div class="contentContainer">
        <b>1. Coverage Period</b><br />
        <span>This warranty is valid for a period of [XX months/years] from the original purchase date, as indicated on
          the purchase receipt.</span>
        </ul>
      </div>
      <div class="contentContainer">
        <b>2. Scope of Warranty</b><br />
        <span>This warranty covers defects in materials and workmanship under normal use It does not cover damage caused
          by
          misuse, neglect, unauthorized modifications, or external factors such as accidents or natural
          disasters.</span>
        </ul>
      </div>
      <div class="contentContainer">
        <b>3. Warranty Claim Process</b><br />
        <span>To file a warranty claimq : </span><br />
        <ul style="list-style-type:disc;">
          <li>Contact our support team at [support email or phone number],</li>
          <li>Provide proof of purchase and a detailed description of the issue,</li>
          <li>Follow instructions for returning the product or scheduling an inspection,</li>
        </ul>
        </ul>
      </div>
      <div class="contentContainer">
        <b>4. Remedier</b><br />
        <span>If a defect is confirmed, DIY Design Space will, at its discretion : </span><br />
        <ul style="list-style-type:disc;">
          <li> Repair the defective item.</li>
          <li> Replace it with an identical or equivalent product.</li>
          <li> Refund the purchase price.</li>
        </ul>
        </ul>
      </div>
      <div class="contentContainer">
        <b>5. Limitations</b><br />
        <span>This warranty is non-transferable and valid only for the original purchaser. It excludes liability for
          incidental or
          consequential damages arising from the use of the product.</span>
        </ul>
      </div>
      <div class="contentContainer">
        <b>6. Additional Termr</b><br />
        <span>For products requiring maintenance, adherence to the recommended care guidelines is necessary for warranty
          validity.</span><br /><br />
        <span>This warranty gives you specific legal rights, and you may also have other rights that vary by
          jurisdiction. For complete
          details, please visit our website at [website link].</span><br /><br />
        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque in erat vitae purus venenatis vestibulum
          sed id nisi.</span><br />
        <span>Vestibulum sit amet risus in nibh tempor ullamcorper. Sed porta vestibulum dui, et feugiat libero
          vestibulum quis.</span><br />
        <span>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Quisque sit
          amet neque
          augue. Nam ornare nisl sed dui venenatis, a accumsan purus imperdiet. Etiam iaculis leo ac risus efficitur
          sollicitudin. Proin
          tincidunt feugiat lorem, a cursus diam.</span><br /><br />
        <span>Proin et lobortis lacus. Donec auctor tempor commodo. Morbi tristique enim magna, sit amet rutrum metus
          facilisis
          lobortis. Nullam quam nisi, ultricies nec ultricies ut, faucibus facilisis nisi. Aenean eget interdum erat.
          Mauris efficitur
          lobortis sem, vitae pretium augue luctus consectetur. In in justo eget ante dapibus porttitor. Etiam
          vestibulum tempor mi
          ac aliquam.</span>
        </ul>
      </div>
      <div class="contentContainer">
        <b>DisClaimer:</b>DIY Design Space not take resposibility for the
        accuracy of measurements or furniture arrangements. The prices
        displayed here apply to items in stock at the time of the offer's
        issuance. All delivery, assembly, and installation services required
        are billed are billed separately and are not included in the price.
        While we strive to ensure the accuracy of the information in this
        program, we apologize for any errors that may occur.'
      </div>
    </div>
    <div class="footerStyle">
      <span>＠ DIY.DS v1.0/2024</span>
      <span>DIY Design Space 2024 All rights reserved</span>
    </div>
  </div>
</body>

</html>
`
const previewList = `
<html>
<head>
  <style>
    * {
      box-sizing: border-box;
      font-family: sans-serif;
    }
    body {
      background-color: aqua;
      width: 60%;
      margin: auto;
    }
    .container {
      width: 100%;
      background-color: white;
      padding: 30px 40px;
    }
    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      border-bottom: 1px solid #878787;
    }
    .logo-container {
      flex: 2;
      display: flex;
      align-items: center;
    }
    .logo-img {
      height: 40px;
      width: 40px;
      background-color: blueviolet;
    }
    .logo-title {
      padding: 0 10px;
    }
    .info-container {
      flex: 3;
      display: flex;
      justify-content: space-between;
      font-family: sans-serif;
      color: #777777;
    }
    .content-container {
      padding: 40px 0;
    }
    .content-body {
      padding: 0 8px;
    }
    .content-header-title {
      font-weight: 700;
    }
    .content-header-desc {
      font-weight: bold;
    }
    .list-container {
      padding: 35px 15px;
    }
    .total-price {
      display: flex;
      justify-content: space-between;
      padding: 0 10px 0 15px;
    }
    .footer-container {
      padding: 15px 0;
      border-top: 1px solid gray;
      display: flex;
      justify-content: space-between;
      font-family: sans-serif;
      color: #777777;
    }
    .list-table {
      width: 100%;
    }
    .list-table-header {
      padding: 10px 0;
    }
    .table-cl1 {
      width: 80%;
      text-align: left;
    }
    .table-cl2 {
      width: 20%;
      text-align: right;
    }
    .product-cell {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .list-product-img {
      margin: 5px 30px;
    }
    .list-product-desc {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-left: 25px;
    }
    .content-desc {
      text-align: justify;
    }
  </style>
</head>
<body>
  <div class="container">
    <div class="header-container">
      <div class="logo-container">
        <div class="logo-img">
          <img src="/assets/rtastore_logo.png" width="100%" height="100%" />
        </div>
        <div class="logo-title">${sessionStorage.getItem('projectTitle')}</div>
      </div>
      <div class="info-container">
        <div class="info-style">Created for: ${sessionStorage.getItem('firstName') + ' ' + sessionStorage.getItem('lastName')}</div>
        <div class="info-style">Date: 04/02/2024</div>
        <div class="info-style">ID: ${sessionStorage.getItem('projectID')}</div>
      </div>
    </div>
    <div class="content-container">
      <div class="content-header">
        <h1 class="content-header-title">List of parts</h1>
        <div class="content-header-desc">
          List of parts with quantities and pricing
        </div>
      </div>
      <div class="content-body">
        <div class="list-container">
          <table class="list-table">
            <thead class="list-table-header">
              <tr>
                <th class="table-cl1">Product</th>
                <th class="table-cl2">Subtotal</th>
              </tr>
            </thead>
            <tbody class="list-table-body">
              <tr>
                <td class="table-cl1">
                  <div class="product-cell">
                    <img
                      src="/assets/rtastore_logo.png"
                      width="50px"
                      height="50px"
                      class="list-product-img"
                    />
                    <div class="list-product-desc">
                      <div>9"3-Drawer Base Cabinet</div>
                      <div>W: 12" D: 24" H: 34.5"</div>
                    </div>
                  </div>
                </td>
                <td class="table-cl2">$438.33</td>
              </tr>
              <tr>
                <td class="table-cl1">
                  <div class="product-cell">
                    <img
                      src="/assets/rtastore_logo.png"
                      width="50px"
                      height="50px"
                      class="list-product-img"
                    />
                    <div class="list-product-desc">
                      <div>9"3-Drawer Base Cabinet</div>
                      <div>W: 12" D: 24" H: 34.5"</div>
                    </div>
                  </div>
                </td>
                <td class="table-cl2">$438.33</td>
              </tr>
              <tr>
                <td class="table-cl1">
                  <div class="product-cell">
                    <img
                      src="/assets/rtastore_logo.png"
                      width="50px"
                      height="50px"
                      class="list-product-img"
                    />
                    <div class="list-product-desc">
                      <div>9"3-Drawer Base Cabinet</div>
                      <div>W: 12" D: 24" H: 34.5"</div>
                    </div>
                  </div>
                </td>
                <td class="table-cl2">$438.33</td>
              </tr>
              <tr>
                <td class="table-cl1">
                  <div class="product-cell">
                    <img
                      src="/assets/rtastore_logo.png"
                      width="50px"
                      height="50px"
                      class="list-product-img"
                    />
                    <div class="list-product-desc">
                      <div>9"3-Drawer Base Cabinet</div>
                      <div>W: 12" D: 24" H: 34.5"</div>
                    </div>
                  </div>
                </td>
                <td class="table-cl2">$438.33</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="total-price">
          <span>Total</span>
          <h4>$1755.78</h4>
        </div>
        <div class="content-desc">
          <b>DisClaimer:</b>DIY Design Space not take resposibility for the
          accuracy of measurements or furniture arrangements. The prices
          displayed here apply to items in stock at the time of the offer's
          issuance. All delivery, assembly, and installation services required
          are billed are billed separately and are not included in the price.
          While we strive to ensure the accuracy of the information in this
          program, we apologize for any errors that may occur.'
        </div>
      </div>
    </div>
    <div class="footer-container">
      <span>＠ DIY.DS v1.0/2024</span>
      <span>DIY Design Space 2024 All rights reserved</span>
    </div>
  </div>
</body>
</html>

`

    return (
      <StyledDialog open={visible} onClose={() => this._closePanel()}>
        <div style={{ display: 'flex', width: '100%', userSelect: 'none ', height : "100%" }}>
          <div
            style={{
              width: '45%',
              display: 'flex',
              padding: '40px 40px 40px 40px',
              flexDirection: 'column'
            }}
          >
            <S.DialogTitle>Download Summary</S.DialogTitle>
            <S.DialogContent style={{ marginTop: 10 }}>
              Your are almost there, customize your summary based on your
              requirements.
            </S.DialogContent>
            <S.DialogContent style={{ marginTop: 30 }}>
              Project infromations:
            </S.DialogContent>
            <S.StyledInputWrapper>
              <S.StyledInput
                value={firstName}
                onChange={e => this.setState({ firstName: e.target.value })}
                onFocus={e => this.setState({ firstNameFocusOn: true })}
                onBlur={e => this.setState({ firstNameFocusOn: false })}
                placeholder={
                  firstNameFocusOn ? 'Enter First Name' : 'First Name'
                }
                required
              />
              {(firstNameFocusOn || !!firstName.length) && (
                <S.StyledInputHeadLabel
                  style={{ color: !firstNameFocusOn && TEXT_COLOR_NEUTRAL_2 }}
                >
                  First Name
                </S.StyledInputHeadLabel>
              )}
              {!!firstName.length && (
                <S.PlusImage
                  maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                  onClick={() => this.setState({ firstName: '' })}
                />
              )}
            </S.StyledInputWrapper>
            <S.StyledInputWrapper>
              <S.StyledInput
                value={lastName}
                onChange={e => this.setState({ lastName: e.target.value })}
                onFocus={e => this.setState({ lastNameFocusOn: true })}
                onBlur={e => this.setState({ lastNameFocusOn: false })}
                placeholder={lastNameFocusOn ? 'Enter Last Name' : 'Last Name'}
                required
              />
              {(lastNameFocusOn || !!lastName.length) && (
                <S.StyledInputHeadLabel
                  style={{ color: !lastNameFocusOn && TEXT_COLOR_NEUTRAL_2 }}
                >
                  Last Name
                </S.StyledInputHeadLabel>
              )}
              {!!lastName.length && (
                <S.PlusImage
                  maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                  onClick={() => this.setState({ lastName: '' })}
                />
              )}
            </S.StyledInputWrapper>
            <S.StyledInputWrapper>
              <S.StyledInput
                value={email}
                onChange={e => this.setState({ email: e.target.value })}
                onFocus={e => this.setState({ emailFocusOn: true })}
                onBlur={e => this.setState({ emailFocusOn: false })}
                placeholder={emailFocusOn ? 'Enter Email' : 'Email'}
                required
              />
              {(emailFocusOn || !!email.length) && (
                <S.StyledInputHeadLabel
                  style={{ color: !emailFocusOn && TEXT_COLOR_NEUTRAL_2 }}
                >
                  Email
                </S.StyledInputHeadLabel>
              )}
              {!!email.length && (
                <S.PlusImage
                  maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                  onClick={() => this.setState({ email: '' })}
                />
              )}
            </S.StyledInputWrapper>
            <S.StyledInputWrapper>
              <S.StyledInput
                value={phone}
                onChange={e => this.setState({ phone: e.target.value })}
                onFocus={e => this.setState({ phoneFocusOn: true })}
                onBlur={e => this.setState({ phoneFocusOn: false })}
                placeholder={
                  phoneFocusOn ? 'Enter Phone Number' : 'Phone Number'
                }
                required
              />
              {(phoneFocusOn || !!phone.length) && (
                <S.StyledInputHeadLabel
                  style={{ color: !phoneFocusOn && TEXT_COLOR_NEUTRAL_2 }}
                >
                  Phone Number
                </S.StyledInputHeadLabel>
              )}
              {!!phone.length && (
                <S.PlusImage
                  maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                  onClick={() => this.setState({ phone: '' })}
                />
              )}
            </S.StyledInputWrapper>
            <S.StyledInputWrapper>
              <S.StyledInput
                value={projectTitle}
                onChange={e => this.setState({ projectTitle: e.target.value })}
                onFocus={e => this.setState({ projectTitleFocusOn: true })}
                onBlur={e => this.setState({ projectTitleFocusOn: false })}
                placeholder={
                  projectTitleFocusOn ? 'Enter Project Title' : 'Project Title'
                }
                required
              />
              {(projectTitleFocusOn || !!projectTitle.length) && (
                <S.StyledInputHeadLabel
                  style={{
                    color: !projectTitleFocusOn && TEXT_COLOR_NEUTRAL_2
                  }}
                >
                  Project Title
                </S.StyledInputHeadLabel>
              )}
              {!!projectTitle.length && (
                <S.PlusImage
                  maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                  onClick={() => this.setState({ projectTitle: '' })}
                />
              )}
            </S.StyledInputWrapper>
            <S.DialogContent style={{ margin: '10px 0px' }}>
              What you want to print:
            </S.DialogContent>
            <FormControlLabel
              style={{ margin: '10px 0px' }}
              control={
                <StyledMuiCheckbox
                  style={{ padding: 'unset' }}
                  checked={question1}
                  onChange={() => this.setState({ question1: !question1 })}
                  value={question1}
                />
              }
              label={
                <S.DialogContents>3D render of the kitchen</S.DialogContents>
              }

            />
            <FormControlLabel
              style={{ margin: '10px 0px' }}
              control={
                <StyledMuiCheckbox
                  style={{ padding: 'unset' }}
                  checked={question2}
                  onChange={() => this.setState({ question2: !question2 })}
                  value={question2}
                />
              }
              label={
                <S.DialogContents>
                  2D floor plan and elevations technical view
                </S.DialogContents>
              }
            />
            <FormControlLabel
              style={{ margin: '10px 0px' }}
              control={
                <StyledMuiCheckbox
                  style={{ padding: 'unset' }}
                  checked={question3}
                  onChange={() => this.setState({ question3: !question3 })}
                  value={question3}
                />
              }
              label={
                <S.DialogContents>
                  List of parts with quantities and pricing
                </S.DialogContents>
              }
            />
            <S.SaveBox >
            <S.SelectTitle style={{color: BG_COLOR_1}}>Save & Download Summary</S.SelectTitle>
          </S.SaveBox>
          </div>
          <div
            style={{
              width: '55%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              padding: '10px 10px 10px 10px',
              borderLeft: `2px solid ${TEXT_COLOR_NEUTRAL_5}`,
              height: "100%",
            }}
          >
            <S.previewPan>
              {question1 && <div style = {{
                backgroundColor : 'white',
                borderRadius : '5px',
                marginBottom : '10px',
                fontSize : '8px'
              }} 
              dangerouslySetInnerHTML={{ __html: preview3D }}/>}
              {question2 && <div style = {{
                backgroundColor : 'white',
                borderRadius : '5px',
                marginBottom : '10px',
                fontSize : '8px'
              }} 
              dangerouslySetInnerHTML={{ __html: preview2D }}/>}
              {question2 && <div style = {{
                backgroundColor : 'white',
                borderRadius : '5px',
                marginBottom : '10px',
                fontSize : '8px'
              }} 
              dangerouslySetInnerHTML={{ __html: elevationView }}/>}
              {question3 && <div style = {{
                backgroundColor : 'white',
                borderRadius : '5px',
                marginBottom : '10px',
                fontSize : '8px'
              }} 
              dangerouslySetInnerHTML={{ __html: previewList }}/>}
              <div style = {{
                backgroundColor : 'white',
                borderRadius : '5px',
                fontSize : '8px'
              }} 
              dangerouslySetInnerHTML={{ __html: warranty }}/>
            </S.previewPan>
          </div>
        </div>
      </StyledDialog>
    );
  }
}

export default DownloadSummaryPopup;
